import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NzCarouselComponent, NzModalRef, NzModalService} from 'ng-zorro-antd';
import {DashboardModalCustomLookComponent} from './dashboard-modal-custom-look-component/dashboard-modal-custom-look.component';
import {combineLatest, Observable, Subject} from 'rxjs';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {DashboardService} from '../../services/dashboard.service';
import {IUserAuthResolveInterface} from '../../interfaces/user-auth-resolve.interface';
import {DashboardModalCustomProductComponent} from './dashboard-modal-custom-product-component/dashboard-modal-custom-product.component';
import {AdminInfluencersService} from '../../services/admin-influencers.service';
import {BrandEnum, BrandTypeRelation} from '@enums';
import {BrandService} from '../../services/brand.service';
import {RULE_ALLOW_EDIT_REVIEWS, RULE_SHOW_ADD_EDIT_LOOK_NAME} from '../../helpers/BrandRulesManager';
import {select, State, Store} from '@ngrx/store';
import {AppState} from '../../state/state';
import {selectInfluencer} from '../../state/selectors';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('carousel') private carousel: NzCarouselComponent;
  @ViewChild('carouselMobile') private carouselMobile: NzCarouselComponent;

  public readonly rules = {
    isReview: RULE_ALLOW_EDIT_REVIEWS,
    isLook: RULE_SHOW_ADD_EDIT_LOOK_NAME
  };

  private lookModal: NzModalRef;
  private productModal: NzModalRef;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public readonly cardBodyStyle = {'padding-right': '10px', 'padding-top': '10px'};
  public performanceOverview$: Observable<DashboardPerformanceOverviewInterface>;
  public influencerId: string;
  public influencerId$ = new Subject<string>();
  public isAdmin: boolean;
  public brand: string = BrandEnum['Il Makiage'];
  public brand$ = new Subject<string>();
  public isReview = false;

  // max number of showed items in list
  public numberOfLooks = 3;
  public numberOfReviews = 3;
  public numberOfProducts = 5;
  public numberOfMostLovedLooks = 3;
  public numberOfMostLovedReviews = 3;

  public myLooksPerformanceTop: {
    total: number,
    totalLooks: number,
    totalReviews: number,
    looks: DashboardMyLooksInterface[],
    reviews: DashboardMyLooksInterface[],
    tempList: DashboardMyLooksInterface[]
  };
  public bestSellingProductsTop: { total: number, list: DashboardBestProductsInterface[], tempList: DashboardBestProductsInterface[] };
  public looksPerformanceTop: {
    total: number,
    totalLooks: number,
    totalReviews: number,
    tempList: DashboardMyLooksInterface[],
    looks: DashboardMyLooksInterface[],
    reviews: DashboardMyLooksInterface[],
  };

  constructor(
    private route: ActivatedRoute,
    private modalService: NzModalService,
    private dashboardService: DashboardService,
    private adminInfluencersService: AdminInfluencersService,
    private brandService: BrandService,
    private store: Store<{ app: AppState }>,
  ) {
  }

  ngOnInit() {
    const userData: IUserAuthResolveInterface = this.route.snapshot.parent.data.userData;
    this.influencerId = userData.influencerId;
    this.isAdmin = userData.isAdmin;

    this.subscribeOnLoadData();
    this.subscribeBrandService();

    this.store.pipe(
      select(selectInfluencer),
      takeUntil(this.destroy$),
      filter(e => !!e)
    ).subscribe(influencer => {
      this.influencerId = influencer._id;
      this.influencerId$.next(influencer._id);
    });
  }

  public subscribeBrandService() {
    this
      .brandService
      .getCurrentBrand()
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(1000)
      )
      .subscribe((brand) => {
        this.brand = brand === '' ? this.brandService.defaultBrand : brand;
        this.isReview = this.brand !== this.brandService.defaultBrand;
        this.brand$.next(this.brand);
      });
  }

  public subscribeOnLoadData() {
    combineLatest([
      this.influencerId$,
      this.brand$
    ])
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(value => {
        this.loadPageData();
      });
  }

  ngOnDestroy(): void {
    this.modalService.closeAll();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private loadPageData() {
    this.performanceOverview$ = this.dashboardService.getPerformanceOverview(this.influencerId);

    this.dashboardService.getMyLooksPerformanceData(this.influencerId)
      .subscribe((dashboardMyLooks: DashboardMyLooksInterface[]) => {
        const looks = dashboardMyLooks.filter(e => e.lookType === 'look');
        const reviews = dashboardMyLooks.filter(e => e.lookType === 'review');
        this.myLooksPerformanceTop = {
          total: dashboardMyLooks.length,
          totalLooks: looks.length,
          totalReviews: reviews.length,
          tempList: [...dashboardMyLooks],
          looks,
          reviews,
        };
        this.looksPerformanceTop = {
          totalLooks: looks.length,
          totalReviews: reviews.length,
          total: dashboardMyLooks.length,
          tempList: [...dashboardMyLooks],
          looks,
          reviews,
        };
      });

    this.dashboardService.getBestSellingProducts(this.influencerId)
      .subscribe((dashboardBestProducts: DashboardBestProductsInterface[]) => {
        this.bestSellingProductsTop = {
          total: dashboardBestProducts.length,
          list: [...dashboardBestProducts],
          tempList: [...dashboardBestProducts]
        };
      });
  }

  private sortFunc(obj: { list?: any[], looks?: any[], reviews?: any[], tempList: any[] }, sort: { key: string, value: string }, attrKey) {
    if (sort.key && sort.value) {
      const sorted = obj[attrKey].sort((a, b) => {
        const firstVal = a[sort.key];
        const secondVal = b[sort.key];
        return sort.value === 'ascend'
          ? firstVal - secondVal
          : secondVal - firstVal;
      });
      obj[attrKey] = [...sorted];
    } else {
      obj[attrKey] = [...obj.tempList];
    }
  }

  public showMore(numberShowedItems: string, max: number): void {
    this[numberShowedItems] += 5;
    if (this[numberShowedItems] > max) {
      this[numberShowedItems] = max;
    }
  }

  // tableName is defined inside sort function from HTML
  public sort(sort: { key: string, value: string }, tableName: string, attrKey: string): void {
    switch (tableName) {
      case 'bestProducts':
        this.sortFunc(this.bestSellingProductsTop, sort, attrKey);
        break;
      case 'looksPerformance':
        this.sortFunc(this.looksPerformanceTop, sort, attrKey);
        break;
      case 'myLooksPerformance':
        this.sortFunc(this.myLooksPerformanceTop, sort, attrKey);
        break;
    }
  }

  public next(): void {
    this.carousel.next();
    this.carouselMobile.next();
  }

  public pre(): void {
    this.carousel.pre();
    this.carouselMobile.pre();
  }

  public openLookModal(item: DashboardMyLooksInterface) {
    if (this.lookModal) {
      this.lookModal.destroy();
    }

    this.lookModal = this.modalService.create({
      nzContent: DashboardModalCustomLookComponent,
      nzFooter: null,
      nzComponentParams: {
        item
      }
    });
  }

  public openProductModal(item: DashboardBestProductsInterface) {
    if (this.productModal) {
      this.productModal.destroy();
    }

    this.productModal = this.modalService.create({
      nzContent: DashboardModalCustomProductComponent,
      nzFooter: null,
      nzComponentParams: {
        item
      }
    });
  }
}
