import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RegistrationService } from "../../../services/registration.service";
import { Router } from "@angular/router";

const BOTH_BRAND_SELECTOR = 'both';

@Component({
  selector: 'app-sign-up-request',
  templateUrl: './sign-up-request.component.html',
  styleUrls: ['./sign-up-request.component.less']
})
export class SignUpRequestComponent implements OnInit {

  public form: FormGroup;
  public loading = false;
  public brandsSelector: Array<{ label: string, value: string, checked: boolean }> = [
    { label: 'IL MAKIAGE', value: 'il-makiage', checked: true },
    { label: 'SpoiledChild', value: 'spoiled-child', checked: false },
    { label: 'Both', value: 'both', checked: false }
  ];

  private bothBrandSelected = false;

  constructor(private fb: FormBuilder, private registrationService: RegistrationService, private router: Router) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      instagramUrl: ['', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      youtubeURL: ['', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      tikTokUrl: ['', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
      brands: ['', [Validators.required, this.minSelectedCheckboxes(true)]],
      other: [''],
    }, { updateOn: 'change' });
  }

  minSelectedCheckboxes(isRequired = true): ValidatorFn {
    return ($event: FormArray): ValidationErrors | null => {
      if (Array.isArray($event.value)) {
        return $event.value.filter(item => item.checked).length > 0 ? null : { notSelected: true };
      }
      return null;
    };
  }

  updateCheckedState = () => {
    const isBothBrandSelectorChecked = this.brandsSelector.find(item => item.value === BOTH_BRAND_SELECTOR).checked;
    if (this.bothBrandSelected === false && isBothBrandSelectorChecked) {
      this.bothBrandSelected = true;
      this.brandsSelector.forEach(item => {
        if (item.value !== BOTH_BRAND_SELECTOR) {
          item.checked = true;
        }
      });
    }
    if (!isBothBrandSelectorChecked) {
      this.bothBrandSelected = false;
    }
  }

  submit = () => {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }

    if (this.form.valid && !this.loading) {
      const payload = {
        brands: this.brandsSelector.filter(item => item.checked).map(item => item.value),
        email: this.form.get('email').value,
        firstName: this.form.get('firstName').value,
        instagramUrl: this.form.get('instagramUrl').value,
        lastName: this.form.get('lastName').value,
        other: this.form.get('other').value,
        tikTokUrl: this.form.get('tikTokUrl').value,
        youtubeURL: this.form.get('youtubeURL').value
      };

      this.loading = true;
      this.registrationService.requestRegistration(payload)
        .subscribe(() => {
          this.loading = false;
          this.router.navigate([`/sign-in`])
            .catch(err => console.error(err));
        });
    }
  }
}
