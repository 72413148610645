import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-modal-custom-product-component',
  templateUrl: './dashboard-modal-custom-product.component.html',
  styleUrls: ['./dashboard-modal-custom-product.component.less']
})
export class DashboardModalCustomProductComponent implements OnInit {

  @Input() item: DashboardBestProductsInterface;

  constructor() {
  }

  ngOnInit() {
  }

}
