import {BrandEnum} from '@enums';

const multiplier = ((n) => {
  return () => {
    n = n * 2;

    // tslint:disable-next-line:no-bitwise
    return n;
  };
})(2);

// tslint:disable-next-line:no-bitwise
export const RULE_SHOW_PROFILE_DESCRIPTION = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOW_REVIEW_NAME = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOW_ADD_EDIT_LOOK_NAME = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOW_REVIEW_STEP = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOW_LOOK_STEP = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_ALLOW_STORE_IMAGES_TO_REVIEW_PATH = multiplier();

// tslint:disable-next-line:no-bitwise
export const RULE_LOOK_BEFORE_IMAGE = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_LOOK_AFTER_IMAGE = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_LOOK_PRODUCT_IMAGE = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_LOOK_LIFESTYLE_IMAGE = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_LOOK_APPLICATION_SHOOT = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_LOOK_AFTER_SHOOT = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_LOOK_SHELFIE_IMAGE = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_LOOK_LOOK_HEADER = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_LOOK_REVIEW_HEADER = multiplier();
// tslint:disable-next-line:no-bitwise
export const LOOKS_IMAGES = RULE_LOOK_BEFORE_IMAGE + RULE_LOOK_AFTER_IMAGE + RULE_LOOK_PRODUCT_IMAGE + RULE_LOOK_LIFESTYLE_IMAGE;
// tslint:disable-next-line:no-bitwise
export const REVIEW_IMAGES = RULE_LOOK_PRODUCT_IMAGE + RULE_LOOK_APPLICATION_SHOOT;
// tslint:disable-next-line:no-bitwise
export const RULE_LOOK_IMAGE_PRODUCT_DESC = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_REVIEW_IMAGE_PRODUCT_DESC = multiplier();
// tslint:disable-next-line:no-bitwise
export const REQUIRED_LOOK_IMAGES = multiplier();
// tslint:disable-next-line:no-bitwise
export const REQUIRED_REVIEW_IMAGES = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOR_HAIR_CONCERN = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOR_HAIR_TYPE = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOW_REVIEW_TAG = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOW_LOOK_TAG = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOW_NIGHT_BLOCK = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOW_QUOTE_SKIN_TAKEAWAYS = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_ALLOW_BACK_TAGS = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_ALLOW_EDIT_LOOKS = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_ALLOW_EDIT_REVIEWS = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOW_SHORT_TUTORIAL = multiplier();
export const RULE_SHOW_PROFILE_VIDEO_TIMESTAMP = multiplier();
// tslint:disable-next-line:no-bitwise
export const RULE_SHOW_TIMESTAMP_CONTAINER = multiplier();

export const BRAND_RULES = {
  [BrandEnum['Il Makiage']]: [RULE_SHOW_PROFILE_DESCRIPTION + RULE_SHOW_PROFILE_VIDEO_TIMESTAMP + RULE_SHOW_REVIEW_NAME + RULE_SHOW_ADD_EDIT_LOOK_NAME + RULE_SHOW_LOOK_STEP
  + LOOKS_IMAGES + RULE_LOOK_IMAGE_PRODUCT_DESC + RULE_LOOK_LOOK_HEADER + REQUIRED_LOOK_IMAGES + RULE_SHOW_LOOK_TAG + RULE_SHOW_NIGHT_BLOCK
  + RULE_SHOW_SHORT_TUTORIAL + RULE_ALLOW_EDIT_LOOKS + RULE_SHOW_TIMESTAMP_CONTAINER],
  // tslint:disable-next-line:no-bitwise
  [BrandEnum['SpoiledChild']]: [RULE_SHOW_REVIEW_STEP
  + RULE_ALLOW_STORE_IMAGES_TO_REVIEW_PATH + REVIEW_IMAGES + RULE_REVIEW_IMAGE_PRODUCT_DESC + RULE_LOOK_AFTER_SHOOT
  + RULE_LOOK_SHELFIE_IMAGE + RULE_LOOK_REVIEW_HEADER + REQUIRED_REVIEW_IMAGES + RULE_SHOR_HAIR_CONCERN + RULE_SHOR_HAIR_TYPE
  + RULE_SHOW_REVIEW_TAG + RULE_SHOW_QUOTE_SKIN_TAKEAWAYS + RULE_ALLOW_BACK_TAGS + RULE_ALLOW_EDIT_REVIEWS]
};

export class BrandRulesManager {
  public static isActionAvailable = (brand: string, action: number) => {
    // tslint:disable-next-line:no-bitwise
    return Math.abs(BRAND_RULES[brand] & action) > 0;
  }
}
