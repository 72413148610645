import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren, ViewEncapsulation} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {NzMessageService, NzSelectComponent} from 'ng-zorro-antd';
import {ProductsSelectService} from '../../../../services/products-select.service';
import {ProductsOrderSetInterface} from '../../../../interfaces/products-order-set.interface';
import {ProductOrderCategoryInterface} from '../../../../interfaces/product-order-category.interface';
import {CustomProductItemInterface} from '../../../../interfaces/custom-product-item.interface';
import {LookItemInterface} from '../../../../interfaces/look-item.interface';
import {ProductOrderItemInterface} from '../../../../interfaces/product-order-item.interface';
import {BrandEnum, BrandProductRelation} from '@enums';
import {BrandService} from '../../../../services/brand.service';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';

export const ProductCategoriesOrder = {'hair': 1, 'serum': 2, 'moisturizer': 3, 'supplements': 4};
// Prefixes for external product categories specially for don't do the changes inside DB categories
export const ProductCategoriesNamePrefixes = {'moisturizer': 'Skin', 'serum': 'Skin'};

@Component({
  selector: 'app-new-look-step4',
  templateUrl: './new-look-step4.component.html',
  styleUrls: ['./new-look-step4.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class NewLookStep4Component implements OnInit {

  @ViewChildren(NzSelectComponent) public selectComponentViewChildren: QueryList<NzSelectComponent>;
  @Input() public lookId: string;
  @Input() public influencerId: string;
  @Input() public isAdmin: boolean;
  @Input() public isContentEditor: boolean;
  @Input() public submitLoading: boolean;
  @Input() public countryCode = 'US';
  @Output() public onSubmitForm = new EventEmitter<any>();

  public expandedCategories: { [key: string]: boolean } = {};
  public isDefaultBrand = true;
  public isSubmitDisabled = true;
  public isTouched = false;
  public loadingSubmit: boolean;
  readonly productCategoriesNamePrefixes = ProductCategoriesNamePrefixes;
  public productsSet$: Observable<ProductsOrderSetInterface[]>;
  public selectedCustomProducts: { [key: string]: CustomProductItemInterface[] } = {};
  public selectedProducts: { [key: string]: string[] } = {};
  public selectedProductsAdditionalInfo: { [key: string]: { [key: string]: ProductOrderItemInterface } } = {};
  public submitPublishing: boolean;

  private brand: string = BrandEnum['Il Makiage'];
  private _lookData: LookItemInterface;
  private productLimits = {
    min: 1,
    max: 3
  };
  private subscription: Subscription = new Subscription();

  get lookData(): LookItemInterface {
    return this._lookData;
  }

  @Input() set lookData(data: LookItemInterface) {
    if (data) {
      this._lookData = data;
      this.selectedProducts = {};
      this.selectedCustomProducts = {};
      this._lookData.products.forEach((product: ProductOrderItemInterface) => {
        this.pushSelectedProduct(product.product_category, product.product_category + '_' + product.name, product);
      });

      this._lookData.customProducts.forEach((customProduct: CustomProductItemInterface) => {
        if (!this.selectedCustomProducts[customProduct.category]) {
          this.selectedCustomProducts[customProduct.category] = [];
        }
        this.selectedCustomProducts[customProduct.category].push(customProduct);
      });
    }
  }

  constructor(
    private productsSelectService: ProductsSelectService,
    private message: NzMessageService,
    private brandService: BrandService
  ) {
  }

  ngOnInit(): void {
    this.subscribeOnBrandChange();
  }

  public capitalize(s) {
    if (typeof s !== 'string') {
      return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }

  public submitForm(status: number): void {
    if (Object.keys(this.selectedProducts).length > 0) {
      if (status === 2) {
        this.loadingSubmit = true;
      } else if (status === 1) {
        this.submitPublishing = true;
      }

      const products = [].concat(...Object.values(this.selectedProducts));
      const customProducts = [];
      Object.values(this.selectedCustomProducts).forEach(categoryArr => {
        categoryArr.forEach(item => {
          customProducts.push(item._id);
        });
      });

      this.checkLimits();

      if (this.isSubmitDisabled && !this.isDefaultBrand) {
        return;
      }

      this.onSubmitForm.emit({step: 4, status, products, customProducts});
    } else {
      const message = this.brand === this.brandService.defaultBrand
        ? 'Please select at least one product!'
        : 'Please select the product(s) in your review';
      this.message.error(message, {nzDuration: 5000});
    }
  }

  public addProduct(item: ProductOrderCategoryInterface): void {
    if (this.selectedProducts[item.subcategory]) {
      delete this.selectedProducts[item.subcategory];
    }
  }

  public addProductWithOneColor(category: string, item: ProductOrderCategoryInterface) {
    this.isTouched = true;
    if (this.selectedProducts[category + '_' + item.subcategory]) {
      this.removeSelectedProduct(category, category + '_' + item.subcategory);
    } else {
      this.pushSelectedProduct(category, category + '_' + item.subcategory, item.specifics[0]);
    }
  }

  private pushSelectedProduct(category, key, item) {
    if (typeof this.selectedProducts[key] === 'undefined') {
      this.selectedProducts[key] = [];
    }
    this.selectedProducts[key].push(item._id);

    if (typeof this.selectedProductsAdditionalInfo[category] === 'undefined') {
      this.selectedProductsAdditionalInfo[category] = {};
    }
    this.selectedProductsAdditionalInfo[category][key] = item;
    this.checkLimits();
  }

  private checkLimits() {
    const quantity = Object.keys(this.selectedProducts).length;
    this.isSubmitDisabled = quantity > this.productLimits.max || quantity < this.productLimits.min;

    if (quantity > this.productLimits.max && !this.isDefaultBrand) {
      this.message.error('Reviewer cannot add more than 3 products', {nzDuration: 5000});
    }
  }

  public removeSelectedProduct(category, key: string) {
    delete this.selectedProducts[key];
    delete this.selectedProductsAdditionalInfo[category][key];
    if (Object.keys(this.selectedProductsAdditionalInfo[category]).length === 0) {
      delete this.selectedProductsAdditionalInfo[category];
    }
    this.checkLimits();
  }

  public addCustomProduct(item: CustomProductItemInterface) {
    this.productsSelectService.submitNewCustomProduct(item)
      .subscribe((data: any) => {
        if (!this.selectedCustomProducts[item.category]) {
          this.selectedCustomProducts[item.category] = [];
        }
        this.selectedCustomProducts[item.category] = [data, ...this.selectedCustomProducts[item.category]];
      });
  }

  public removeCustomProduct(item: CustomProductItemInterface, category: string) {
    this.selectedCustomProducts[category] = this.selectedCustomProducts[category].filter(product => product._id !== item._id);
  }

  public selectionChanged() {
    this.selectComponentViewChildren.map(component => component.closeDropDown());
  }

  public toggleBlock(blockName) {
    if (typeof this.expandedCategories[blockName] === 'undefined') {
      this.expandedCategories = {};
      this.expandedCategories[blockName] = true;
    } else {
      this.expandedCategories = {};
    }
  }

  private subscribeOnBrandChange() {
    this.subscription.add(
      this.brandService.getCurrentBrand().pipe(
        distinctUntilChanged(),
        tap(e => {
          this.brand = e === '' ? this.brandService.defaultBrand : e;
          this.isDefaultBrand = this.brand === this.brandService.defaultBrand;
        })
      ).subscribe(e => {
        this.loadProductList();
      })
    );
  }

  private loadProductList() {
    this.productsSet$ = this
      .productsSelectService
      .getProductsList(BrandProductRelation[this.brand], this.countryCode, this.brand)
      .pipe(
        map((products: ProductsOrderSetInterface[]) => {
          if (this.brand !== this.brandService.defaultBrand) {
            const result = {};
            products.forEach(e => {
              if (ProductCategoriesOrder[e.category]) {
                result[ProductCategoriesOrder[e.category]] = e;
              }
            });

            return Object.values(result);
          } else {
            return products;
          }
        })
      );
  }
}
