import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NzCarouselComponent} from 'ng-zorro-antd';
import {LookItemInterface} from '../../../interfaces/look-item.interface';
import {LookStatusColorEnum} from '../../../enums/look-status-color.enum';
import {MyLooksService} from '../../../services/my-looks.service';
import {JwplayerComponent} from '../../jwplayer/jwplayer.component';
import {LookCardStylingInterface} from '../../../interfaces/look-card-styling.interface';
import {BrandRulesManager, RULE_ALLOW_EDIT_LOOKS, RULE_ALLOW_EDIT_REVIEWS, RULE_SHOW_REVIEW_NAME} from '../../../helpers/BrandRulesManager';

@Component({
  selector: 'app-single-look',
  templateUrl: './single-look.component.html',
  styleUrls: ['./single-look.component.less']
})
export class SingleLookComponent implements OnInit {
  private _look: LookItemInterface;

  public readonly rules = {
    showLookName: RULE_SHOW_REVIEW_NAME,
    editReview: RULE_ALLOW_EDIT_REVIEWS,
    editLooks: RULE_ALLOW_EDIT_LOOKS,
  };

  @Input() isAdmin: boolean;
  @Input() styling: LookCardStylingInterface;
  @Input() isContentEditor: boolean;
  @Input() playerId: string;
  @Input() brand: string;
  @Output() loadMyLooks: EventEmitter<boolean> = new EventEmitter<boolean>();
  public LookStatusColorEnum: typeof LookStatusColorEnum = LookStatusColorEnum;
  @ViewChild(JwplayerComponent) jwpPlayerChild: JwplayerComponent;

  get look(): LookItemInterface {
    return this._look;
  }

  @Input() set look(look: LookItemInterface) {
    this._look = look;
    this._look.mediaContent = [];
    [
      'afterImage',
      'applicationShoot',
      'video',
      'beforeImage',
      'afterShoot',
      'bodyImage',
      'productsImage',
      'shelfieImage'
    ].forEach(key => {
      if (!!look[key]) {
        this._look.mediaContent.push(look[key]);
      }
    });

    if (this._look.mediaContent.length === 0) {
      this._look.mediaContent.push({
        displayUrl: '../../../../assets/imgs/no-image.png'
      });
    }
  }

  constructor(private myLooksService: MyLooksService, private router: Router) {
  }

  ngOnInit() {
  }

  public duplicateLook(influencerId: string, lookId: string) {
    this.myLooksService.duplicateLook(influencerId, lookId)
      .subscribe(() => {
        this.loadMyLooks.emit(true);
      });
  }

  public updateLookStatus(statusId: number): void {
    this.myLooksService.updateLookStatus(this.look._id, statusId)
      .subscribe(() => {
        this.loadMyLooks.emit(true);
      });
  }

  public navigateToEditPage(id: string) {
    let path = null;
    switch (true) {
      case BrandRulesManager.isActionAvailable(this.brand, this.rules.editLooks):
        path = 'edit-look';
        break;
      case BrandRulesManager.isActionAvailable(this.brand, this.rules.editReview):
        path = 'edit-review';
        break;
    }

    this.router.navigate([path, this.look.influencerRef._id, id])
      .catch(err => console.error(err));
  }

  public slideCarousel(e: NzCarouselComponent, isNext: boolean): void {
    // checking if video is instanced
    if (this.jwpPlayerChild && this.jwpPlayerChild.isVideoPlaying) {
      this.jwpPlayerChild.stopVideo();
    }
    isNext ? e.next() : e.pre();
  }

}
