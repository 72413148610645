import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {from} from 'rxjs';
import {RegistrationService} from '../../../services/registration.service';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {

  public forgotPasswordForm: FormGroup;
  public wrongEmailErrorMsg: string;
  public loading: boolean;

  constructor(private fb: FormBuilder, private registrationService: RegistrationService, private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService) {
    this.initForm();
  }

  ngOnInit() {
  }

  private initForm() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    }, {updateOn: 'blur'});
  }

  public validate(event: any, field: string) {
    this.forgotPasswordForm.get(field).setValue(event.target.value);
  }

  public submitForgotPassword() {
    for (const i in this.forgotPasswordForm.controls) {
      this.forgotPasswordForm.controls[i].markAsDirty();
      this.forgotPasswordForm.controls[i].updateValueAndValidity();
    }

    if (this.forgotPasswordForm.valid) {
      this.loading = true;
      const email = this.forgotPasswordForm.get('email').value;

      from(this.registrationService.forgotPassword(email))
        .subscribe(
          () => {
            this.loading = false;
            this.storage.set(environment.signUpStorageKey, email);
            this.router.navigate(['/reset-password'])
              .catch(err => console.error(err));
          },
          err => {
            this.loading = false;
            this.wrongEmailErrorMsg = err.message;
          });
    }
  }

}
