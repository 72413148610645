import {
  Component,
  ComponentFactoryResolver, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DatePipe} from '@angular/common';
import {FileUploader} from 'ng2-file-upload';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {NzMessageService, NzNotificationService} from 'ng-zorro-antd';
import * as filestack from 'filestack-js';
import {AppConstants} from '../../../../app-constants';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {NewLookService} from '../../../../services/new-look.service';
import {LookItemInterface} from '../../../../interfaces/look-item.interface';
import {DynamicVideoTimestampSelectorComponent} from './dynamic-video-timestamp-selector/dynamic-video-timestamp-selector.component';
import {DynamicVideoTimestampsAnchorDirective} from '../../../../directives/dynamic-video-timestamps-anchor.directive';
import {IVideoTimestampItemInterface} from '../../../../interfaces/video-timestamp-item.interface';
import {environment} from '../../../../../environments/environment';
import {WindowService} from '../../../../services/window.service';
import {
  RULE_SHOW_PROFILE_VIDEO_TIMESTAMP,
  BrandRulesManager,
  RULE_SHOW_LOOK_STEP,
  RULE_SHOW_REVIEW_STEP,
  RULE_SHOW_SHORT_TUTORIAL,
  RULE_SHOW_TIMESTAMP_CONTAINER
} from '../../../../helpers/BrandRulesManager';
import {BrandEnum} from '@enums';
import {BrandService} from '../../../../services/brand.service';
import {LookVideoInterface} from '../../../../interfaces/look-video.interface';

enum VideoUploadState {
  READY = 'READY',
  INPROGRESS = 'INPROGRESS',
  SUCCESS = 'SUCCESS',
}

export const TYPE_REVIEW = 'review-video';
export const TYPE_TUTORIAL = 'tutorial-video';
export const TYPE_SHORT = 'short-video';

export interface VideoForUploadUpdate {
  type: string;
  uploadedVideoUrl: string;
  name: string;
  id: string;
  size: number;
  rotate?: number;
}

@Component({
  selector: 'app-new-look-step2',
  templateUrl: './new-look-step2.component.html',
  styleUrls: ['./new-look-step2.component.less'],
  providers: [DatePipe]
})
export class NewLookStep2Component implements OnInit, OnChanges, OnDestroy {

  public readonly typeTutorial = TYPE_TUTORIAL;
  public readonly typeReview = TYPE_REVIEW;
  public readonly typeShort = TYPE_SHORT;
  public readonly rules = {
    videoTimestamp: RULE_SHOW_PROFILE_VIDEO_TIMESTAMP,
    showLookStep: RULE_SHOW_LOOK_STEP,
    showReviewStep: RULE_SHOW_REVIEW_STEP,
    showShortTutorialVideo: RULE_SHOW_SHORT_TUTORIAL,
    showTimestampContainer: RULE_SHOW_TIMESTAMP_CONTAINER
  };

  public loadingSubmit: boolean;
  public nzFormat = 'HH:mm';

  @ViewChild(DynamicVideoTimestampsAnchorDirective) private container: DynamicVideoTimestampsAnchorDirective;
  @Input() public lookData: LookItemInterface;
  @Input() public submitLoading: boolean;
  @Input() public isAdmin: boolean;
  @Input() public isContentEditor: boolean;
  @Output() public onSubmitForm = new EventEmitter<any>();
  @Input() private lookId: string;
  @Input() private influencerId: string;
  @ViewChild('reviewVideoElement') reviewVideoElement: ElementRef;

  public readonly guidelinesLink = `${environment.staticFilesUrl}/influencers-assets/documents/VIDEO_GUIDELINE_DECK_2019_07.pdf`;
  public selectedVideoFile: { name: string, size: number };
  public selectedShortVideoFile: { name: string, size: number };
  public uploadedVideoId: string;
  public uploadedShortVideoId: string;
  public videoUploadState: VideoUploadState = VideoUploadState.READY;
  public shortVideoUploadState: VideoUploadState = VideoUploadState.READY;
  public uploadedVideoUrl: string;
  public shortVideoUploadedVideoUrl: string;
  public previewUrl: string;
  public shortVideoPreviewUrl: string;
  public safeURL: SafeResourceUrl;
  public safeUploadedVideoUrl: SafeResourceUrl;
  public shortVideoSafeUploadedVideoUrl: SafeResourceUrl;
  public uploadTime = '0 sec';
  public shortVideoUploadTime = '0 sec';
  public uploadProgress = 0;
  public shortVideoUploadProgress = 0;
  public uploadedDate: Date;
  public shortVideoUploadedDate: Date;
  public startUploadTime: number;
  public shortVideoStartUploadTime: number;
  public hasBaseDropZoneOver = false;
  public selectedVideoTimestamps: IVideoTimestampItemInterface[] = [];
  public showVideoInTinder = false;
  public allowSubmitVideoUploader: boolean = null;
  public isTimestampSelected: boolean = null;
  public shortVideoPreviewRotation = 0;
  public tutorialVideoPreviewRotation = 0;
  public brand: string = BrandEnum['Il Makiage'];
  public isReview = false;
  public isTouched = false;

  private readonly filestackClient = filestack.init(environment.fileStack_VideoUploader_ClientKey);
  private readonly videoUrl = 'https://www.youtube.com/embed/QCxT32kbHl4';
  private unsubscribe$ = new Subject();
  private dynamicTimestampsCreated: boolean;
  private dynamicComponentsCounter = 0;
  private uploadControl = {};
  private shortVideoUploadControl = {};
  private readonly fileUploaderOptions = {queueLimit: 1};
  private subscription = new Subscription();
  private videoForQueueUpdate: VideoForUploadUpdate[] = [];

  public videoUploader: FileUploader = new FileUploader(this.fileUploaderOptions);
  public shortVideoUploader: FileUploader = new FileUploader(this.fileUploaderOptions);

  constructor(
    private _sanitizer: DomSanitizer,
    private notification: NzNotificationService,
    private datePipe: DatePipe,
    private newLookService: NewLookService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private message: NzMessageService,
    private windowService: WindowService,
    private brandService: BrandService
  ) {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }

  ngOnInit(): void {
    this.subscribeOnBrand();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lookData && changes.lookData.currentValue && this.lookData) {
      if (this.lookData.video) {
        this.selectedVideoFile = {name: this.lookData.video.name, size: this.lookData.video.fileSize};
        this.uploadedDate = this.lookData.video.createdAt;
        this.uploadedVideoId = this.lookData.video._id;
        this.uploadedVideoUrl = this.lookData.video.rawUrl;
        this.previewUrl = this.lookData.video.encoded_Mp4_360_Url || this.lookData.video.rawUrl;
        this.safeUploadedVideoUrl = this.uploadedVideoUrl && this._sanitizer.bypassSecurityTrustResourceUrl(this.uploadedVideoUrl);
        this.selectedVideoTimestamps = this.lookData.video.categoryTimestamp || [];
        this.buildDynamicTimestampsComponents(this.lookData.video.categoryTimestamp);
      }

      if (this.lookData.shortVideo) {
        this.selectedShortVideoFile = {name: this.lookData.shortVideo.name, size: this.lookData.shortVideo.fileSize};
        this.shortVideoUploadedDate = this.lookData.shortVideo.createdAt;
        this.showVideoInTinder = this.lookData.shortVideo.isVisibleInMatching;
        this.uploadedShortVideoId = this.lookData.shortVideo._id;
        this.shortVideoUploadedVideoUrl = this.lookData.shortVideo.rawUrl;
        this.shortVideoPreviewUrl = this.lookData.shortVideo.encoded_Mp4_360_Url || this.lookData.shortVideo.rawUrl;
        this.shortVideoSafeUploadedVideoUrl = this.shortVideoUploadedVideoUrl && this._sanitizer.bypassSecurityTrustResourceUrl(this.shortVideoUploadedVideoUrl);
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }

  public subscribeOnBrand() {
    this.subscription.add(
      this
        .brandService
        .getCurrentBrand()
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(e => {
          this.brand = e === '' ? this.brandService.defaultBrand : e;
          this.isReview = BrandRulesManager.isActionAvailable(this.brand, RULE_SHOW_REVIEW_STEP);
        })
    );
  }

  public submitForm(): void {
    this.isTouched = true;
    this.allowSubmitVideoUploader = !!this.previewUrl;
    this.isTimestampSelected = !!this.selectedVideoTimestamps[0];
    if (this.allowSubmitVideoUploader
      && (this.isTimestampSelected || !BrandRulesManager.isActionAvailable(this.brand, this.rules.showTimestampContainer))) {
      this.loadingSubmit = true;
      this.newLookService.updateVideoData({
        videoId: this.uploadedVideoId,
        videoData: {
          categoryTimestamp: this.selectedVideoTimestamps.filter(String),
          resolution: this.getPreviewVideoResolution()
        },
        videoQueueUpdate: this.videoForQueueUpdate,
        influencerId: this.influencerId
      })
        .subscribe(() => {
          this.loadingSubmit = false;
          this.onSubmitForm.emit({step: 2, video: this.uploadedVideoId, shortVideo: this.uploadedShortVideoId});
        });
    } else {
      this.windowService.scrollTop();
      this.message.error('Something is missing, check below for more info');
    }
  }

  public onVideoSelected(fileList: File[]): void {
    const file = fileList[0];
    if (file) {
      this.startUploadTime = this.getCurrentTime();
      this.videoUploadState = VideoUploadState.INPROGRESS;

      const onProgress = (evt) => {
        this.uploadProgress = evt.totalPercent;
        const ping = this.getCurrentTime();
        this.uploadTime = `${(ping - this.startUploadTime) / 1000} sec`;
      };

      const ext = file.name.split('.').pop();
      const filename = `video-${this.lookId}-${this.datePipe.transform(new Date(), 'yyyy-MM-dd-hh-mm')}.${ext}`;

      this.uploadControl = {};
      this.filestackClient.upload(file, {onProgress}, {filename}, this.uploadControl)
        .then(res => {
          const ping = this.getCurrentTime();
          this.uploadTime = `${(ping - this.startUploadTime) / 1000} sec`;
          this.startUploadTime = null;

          this.previewUrl = res.url;
          this.uploadedVideoUrl = `${AppConstants.baseS3Protocol}${res.container}.${AppConstants.baseS3Domain}/${res.key}`;
          this.safeUploadedVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.uploadedVideoUrl);
          this.videoUploadState = VideoUploadState.SUCCESS;
          this.uploadVideo(this.uploadedVideoUrl, file.name, file.size, this.isReview ? this.typeReview : this.typeTutorial);
        })
        .catch(err => {
          this.videoUploadState = VideoUploadState.READY;
          this.startUploadTime = null;
          console.log(err);
        });

      this.selectedVideoFile = {name: file.name, size: file.size};
    }
  }

  public changeRotation(type, rotation, video: LookVideoInterface) {
    this.updateVideoToUpdate(type, rotation, video);
  }

  public getPreviewVideoResolution() {
    if (!this.reviewVideoElement) {
      return null;
    }
    return {
      height: this.reviewVideoElement.nativeElement.videoHeight,
      width: this.reviewVideoElement.nativeElement.videoWidth
    };
  }

  public onShortVideoSelected(fileList: File[]): void {
    const file = fileList[0];
    if (file) {
      this.shortVideoStartUploadTime = this.getCurrentTime();
      this.shortVideoUploadState = VideoUploadState.INPROGRESS;

      const onProgress = (evt) => {
        this.shortVideoUploadProgress = evt.totalPercent;
        const ping = this.getCurrentTime();
        this.shortVideoUploadTime = `${(ping - this.shortVideoStartUploadTime) / 1000} sec`;
      };

      const ext = file.name.split('.').pop();
      const filename = `short-video-${this.lookId}-${this.datePipe.transform(new Date(), 'yyyy-MM-dd-hh-mm')}.${ext}`;

      this.shortVideoUploadControl = {};
      this.filestackClient.upload(file, {onProgress}, {
        filename,
        path: '/short-videos/'
      }, this.shortVideoUploadControl)
        .then(res => {
          const ping = this.getCurrentTime();
          this.shortVideoUploadTime = `${(ping - this.shortVideoStartUploadTime) / 1000} sec`;
          this.shortVideoStartUploadTime = null;
          this.shortVideoPreviewUrl = res.url;
          this.shortVideoUploadedVideoUrl = `${AppConstants.baseS3Protocol}${res.container}.${AppConstants.baseS3Domain}/${res.key}`;
          this.shortVideoSafeUploadedVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.shortVideoUploadedVideoUrl);
          this.shortVideoUploadState = VideoUploadState.SUCCESS;
          this.uploadShortVideo(this.shortVideoUploadedVideoUrl, file.name, file.size, TYPE_SHORT);
        })
        .catch(err => {
          this.shortVideoUploadState = VideoUploadState.READY;
          this.shortVideoStartUploadTime = null;
          console.log(err);
        });

      this.selectedShortVideoFile = {name: file.name, size: file.size};
    }
  }

  public addSection(item?: IVideoTimestampItemInterface): void {
    if (!this.container) {
      return;
    }
    this.dynamicComponentsCounter += 1;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicVideoTimestampSelectorComponent);
    const componentRef = this.container.viewContainerRef.createComponent(componentFactory);
    const componentInstance = (componentRef.instance as DynamicVideoTimestampSelectorComponent);

    componentInstance._ref = componentRef;
    componentInstance.index = this.dynamicComponentsCounter;
    componentInstance.videoTimestamp = item;
    componentInstance.selectedTimestamp
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => this.collectVideoTimestamps(data));
  }

  public collectVideoTimestamps(item: { index: number, data?: IVideoTimestampItemInterface }): void {
    if (item.data) {
      item.data.timestamp = this.datePipe.transform(item.data.timestamp, this.nzFormat); // reformat timestamp
      this.selectedVideoTimestamps[item.index] = item.data;
    } else {
      delete this.selectedVideoTimestamps[item.index];
    }
  }

  public setLookPrimaryAsset(assetId: string) {
    this.newLookService.setLookPrimaryAsset({lookId: this.lookId, assetId})
      .subscribe(() => this.notification.create('success',
        'Success',
        'This video will now be the primary asset')
      );
  }

  public updateShortVideoInTinder(value: boolean, videoId: string) {
    this.newLookService.updateVideoData({
      videoId,
      videoData: {isVisibleInMatching: value},
      influencerId: this.influencerId
    })
      .subscribe(() => this.notification.create('success',
        'Success',
        `This video will ${value ? '' : 'not'} be displayed in matching component`)
      );
  }

  public clearQueue(): void {
    this.videoUploader.clearQueue();
    this.selectedVideoFile = undefined;
    this.uploadedVideoUrl = undefined;
    this.safeUploadedVideoUrl = undefined;
    this.uploadedVideoId = null;
    this.previewUrl = null;
    this.videoUploadState = VideoUploadState.READY;
  }

  public clearShortVideoQueue(): void {
    this.shortVideoUploader.clearQueue();
    this.selectedShortVideoFile = undefined;
    this.shortVideoUploadedVideoUrl = undefined;
    this.shortVideoSafeUploadedVideoUrl = undefined;
    this.uploadedShortVideoId = null;
    this.shortVideoPreviewUrl = null;
    this.lookData.shortVideo = null;
    this.shortVideoUploadState = VideoUploadState.READY;
  }

  public cancelUpload() {
    if (this.videoUploadState === VideoUploadState.INPROGRESS) {
      this.uploadControl['cancel']();
    } else if (this.shortVideoUploadState === VideoUploadState.INPROGRESS) {
      this.shortVideoUploadControl['cancel']();
    }
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  private pushVideoToUpdate(type, path, name, size, id) {
    if (!this.videoForQueueUpdate.find(e => e.type === type)) {
      this.videoForQueueUpdate.push({
        uploadedVideoUrl: path,
        type,
        name,
        size,
        id
      });
    }
  }

  private updateVideoToUpdate(type, rotate, video: LookVideoInterface) {
    // tslint:disable-next-line:triple-equals
    const findVideo = this.videoForQueueUpdate.find(e => e.type == type);
    if (findVideo) {
      findVideo.rotate = rotate;
    } else {
      this.videoForQueueUpdate.push({
        type,
        uploadedVideoUrl: video.rawUrl,
        name: video.name,
        size: video.fileSize,
        id: video._id,
        rotate
      });
    }
  }

  private buildDynamicTimestampsComponents(timestampsArray: IVideoTimestampItemInterface[]) {
    if (!this.dynamicTimestampsCreated && BrandRulesManager.isActionAvailable(this.brand, this.rules.showTimestampContainer)) {
      timestampsArray.forEach((item, index) => {
        if (index > 0) {
          this.addSection(item);
        }
      });
    }
    this.dynamicTimestampsCreated = true;
  }

  private uploadVideo(rawUrl, fileName, fileSize, type) {
    this.uploadedDate = new Date();
    const formData = {
      video: {
        videoFile: rawUrl,
        fileName,
        fileSize
      },
      influencerId: this.influencerId
    };

    this.newLookService.uploadNewVideo(formData)
      .subscribe(data => {
        this.uploadedVideoId = data._id;
        this.pushVideoToUpdate(type, rawUrl, fileName, fileSize, data._id);
      });
  }

  private uploadShortVideo(rawUrl, fileName, fileSize, type) {
    this.uploadedDate = new Date();
    const formData = {
      shortVideo: {
        videoFile: rawUrl,
        fileName,
        fileSize,
        isVisibleInMatching: this.showVideoInTinder
      },
      influencerId: this.influencerId
    };

    this.newLookService.uploadNewShortVideo(formData)
      .subscribe(data => {
        this.uploadedShortVideoId = data._id;
        if (this.lookData) {
          this.lookData.shortVideo = data;
        }
        this.pushVideoToUpdate(type, rawUrl, fileName, fileSize, data._id);
      });
  }

  private getCurrentTime = () => new Date().getTime();
}
