import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RegistrationService} from '../../../services/registration.service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.less']
})
export class TermsAndConditionsComponent implements OnInit {

  private influencerId: string;
  public isAgreed = false;
  public isScrolledBottom = false;
  public loading: boolean;

  constructor(private route: ActivatedRoute, private registrationService: RegistrationService, private router: Router) {
  }

  ngOnInit() {
    this.influencerId = this.route.snapshot.params.influencerId;
  }

  @HostListener('scroll', ['$event']) onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 100) {
      this.isScrolledBottom = true;
    }
  }

  public submitTerms(): void {
    this.loading = true;
    if (this.isAgreed) {
      this.registrationService.updateInfluencerRegistrationData(this.influencerId, {isAgreedToTerms: true})
        .subscribe(() => {
          window.dataLayer.push({'event': 'signup-tnc'});
          this.router.navigate(['/my-profile'])
            .catch(err => console.error(err));
          this.loading = false;
        });
    }
  }

}
