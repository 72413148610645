import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, NgModel, ValidatorFn, Validators} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd';
import {TagsService} from '../../../../services/tags.service';
import {ITagItem} from '../../../../interfaces/tag-item.interface';
import {LookItemInterface} from '../../../../interfaces/look-item.interface';
import {debounceTime, distinctUntilChanged, filter, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {BrandService} from '../../../../services/brand.service';
import {combineLatest} from 'rxjs';
import {BrandEnum, BrandModelKeys, BrandTagsLimits} from '../../../../enums/brand.enum';
import {WindowService} from '../../../../services/window.service';
import {
  BrandRulesManager, RULE_ALLOW_BACK_TAGS,
  RULE_SHOW_ADD_EDIT_LOOK_NAME, RULE_SHOW_LOOK_TAG, RULE_SHOW_NIGHT_BLOCK,
  RULE_SHOW_PROFILE_DESCRIPTION, RULE_SHOW_QUOTE_SKIN_TAKEAWAYS,
  RULE_SHOW_REVIEW_TAG
} from '../../../../helpers/BrandRulesManager';

declare let require: any;
const lookCategories: Array<{ name: string, brands: Array<string> }> = require('../../../../stubs/look-categories.json');

@Component({
  selector: 'app-new-look-step1',
  templateUrl: './new-look-step1.component.html',
  styleUrls: ['./new-look-step1.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class NewLookStep1Component implements OnInit, OnChanges, OnDestroy {

  @ViewChild('tagsControl') tagsControl: NgModel;
  @Input() public lookData: LookItemInterface;
  @Input() public submitLoading: boolean;
  @Input() private influencerId: string;
  @Output() public onSubmitForm = new EventEmitter<any>();
  public lookStep1Form: FormGroup;
  public baseCategories: string[] = [];
  public baseDayNight: string[] = ['day', 'night'];
  public isTagsLoading = false;
  public searchTagsList: ITagItem[] = [];
  public tagsList: ITagItem[] = [];
  public tagsStore: {[key: string]: ITagItem} = {};
  public selectedTagList: string[] = [];
  public loadingSubmit: boolean;
  public brand$: Subject<string> = new Subject<string>();
  public searchTags$: Subject<string | null> = new Subject<string | null>();
  public loadMoreTags$: Subject<boolean> = new Subject<boolean>();
  public styling: { isReview: boolean } = {isReview: false};
  public initForm$: Subject<LookItemInterface | any> = new Subject();
  public brand: string = BrandEnum['Il Makiage'];
  public readonly quoteCharsLimit = 100;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private isAllTags = false;
  private tagsOffset = 0;
  private lastSearchTerm: string;

  set tags(tags) {
    this.tagsList = tags;

    tags.forEach(e => {
      if (!this.tagsStore[e.name]) {
        this.tagsStore[e.name] = e;
      }
    });
  }

  get tags() {
    return this.tagsList;
  }

  public readonly rules = {
    profileDescription: RULE_SHOW_PROFILE_DESCRIPTION,
    allowName: RULE_SHOW_ADD_EDIT_LOOK_NAME,
    reviewTags: RULE_SHOW_REVIEW_TAG,
    lookTags: RULE_SHOW_LOOK_TAG,
    showNightBlock: RULE_SHOW_NIGHT_BLOCK,
    showQuoteSkinTakeaways: RULE_SHOW_QUOTE_SKIN_TAKEAWAYS,
    allowBackTags: RULE_ALLOW_BACK_TAGS,
  };

  constructor(
    private fb: FormBuilder,
    private tagsService: TagsService,
    private message: NzMessageService,
    private brandService: BrandService,
    private windowService: WindowService
  ) {
  }

  static minSelectedCheckboxes(isRequired = true): ValidatorFn {
    return (formArray: FormArray) => {
      const selectedCount = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => next ? prev + next : prev, 0);
      return selectedCount >= 1 || !isRequired ? null : {notSelected: true};
    };
  }

  static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {whitespace: true};
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lookData && changes.lookData.currentValue && this.lookData) {
      this.initForm$.next(this.lookData);
      this.selectedTagList = this.lookData.tags.reduce((acc, item) => {
        acc.push(item.name);
        return acc;
      }, []);
    }
  }

  ngOnInit() {
    this.loadGlobalTags();
    this.loadCategories();
    this.subscribeOnSearchTag();
    this.subscribeOnLoadMoreTags();
    this.subscribeStylingCalculate();
    this.subscribeOnInitForm();

    this.subscribeOnBrand();

    this.initForm$.next(this.lookData ? this.lookData : {});
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  public tagsChange() {
    if (BrandRulesManager.isActionAvailable(this.brand, this.rules.allowBackTags)) {
      this.tags = Object.values(this.tagsStore).filter(e => this.selectedTagList.indexOf(e.name) === -1);
    }
  }

  private subscribeOnInitForm() {
    combineLatest([this.brand$, this.initForm$])
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged()
      )
      .subscribe(value => this.initFrom(
        value[1],
        NewLookStep1Component.getExtendedFormRules(value[0])
      ));
  }

  private static getExtendedFormRules(brand) {
    return {
      isDescriptionRequired: BrandRulesManager.isActionAvailable(brand, RULE_SHOW_PROFILE_DESCRIPTION),
      isNameRequired: BrandRulesManager.isActionAvailable(brand, RULE_SHOW_ADD_EDIT_LOOK_NAME),
      isDayNightRequired: BrandRulesManager.isActionAvailable(brand, RULE_SHOW_NIGHT_BLOCK),
      isQuoteSkinTakeawaysRequired: BrandRulesManager.isActionAvailable(brand, RULE_SHOW_QUOTE_SKIN_TAKEAWAYS)
    };
  }

  private isReview = (brand: string) => this.brandService.defaultBrand !== brand;

  private loadCategories() {
    this.brand$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(brand => {
        this.baseCategories = lookCategories.filter(item => item.brands.indexOf(brand) !== -1).map(e => e.name);
      });
  }

  private subscribeStylingCalculate() {
    this.brand$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(brand => {
        this.styling.isReview = this.isReview(brand);
      });
  }

  private subscribeOnLoadMoreTags() {
    combineLatest([this.brand$, this.loadMoreTags$])
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        switchMap(value => combineLatest([
          this.tagsService.getGlobalTags(this.tagsOffset, BrandTagsLimits[value[0]], value[0]),
          of(value[0])
        ]))
      )
      .subscribe((value) => {
        this.tagsOffset += BrandTagsLimits[value[1]];
        this.isAllTags = value[0].length < BrandTagsLimits[value[1]];
        value[0].forEach(tagItem => {
          const index = this.tags.findIndex(tag => tag.name === tagItem.name);
          if (index === -1) {
            this.tags = [...this.tags, tagItem];
          }
        });
      });
  }

  private subscribeOnSearchTag() {
    combineLatest([this.brand$, this.searchTags$])
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        debounceTime(500),
        tap((value) => {
          if (value[1].length === 0) {
            this.searchTagsList = [];
          }
        }),
        filter((value) => value[1].length > 0),
        switchMap((value) => {
          this.isTagsLoading = true;
          this.lastSearchTerm = value[1];
          return this.tagsService.searchTagsByKey(value[1], value[0]);
        })
      )
      .subscribe((data: ITagItem[]) => {
        this.searchTagsList = [...data];
        this.isTagsLoading = false;
      });
  }

  private subscribeOnBrand() {
    this.brandService.getCurrentBrand()
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        switchMap(brand => {
          this.isAllTags = false;
          this.tags = [];
          this.brand = brand === '' ? this.brandService.defaultBrand : brand;
          return of(this.brand);
        })
      )
      .subscribe(brand => this.brand$.next(brand));
  }

  private loadGlobalTags() {
    this.brand$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        switchMap((brand: string) => combineLatest([this
          .tagsService
          .getGlobalTags(this.tagsOffset, BrandTagsLimits[brand], brand), of(brand)]))
      )
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.tagsOffset += BrandTagsLimits[value[1]];
        this.tags = value[0];
      });
  }

  private initFrom(data: LookItemInterface | any, rules: {[key: string]: boolean}): void {
    this.lookStep1Form = this.fb.group({
      name: [data && data.name || null, {
        validators: rules.isNameRequired ? [Validators.required, NewLookStep1Component.noWhitespaceValidator] : [],
        updateOn: 'blur'
      }],
      categories: this.buildDynamicCheckboxFormArray(this.baseCategories, data.categories),
      dayNight: this.buildDynamicCheckboxFormArray(this.baseDayNight, data.dayNight, rules.isDayNightRequired),
      description: [data && data.description, {
        validators: rules.isDescriptionRequired ? [Validators.required, Validators.minLength(50)] : [],
        updateOn: 'blur'
      }],
      quoteSkinTakeaways: [(data && data.quoteSkinTakeaways) || '', {
        validators: rules.isQuoteSkinTakeawaysRequired ? [Validators.required, Validators.maxLength(this.quoteCharsLimit)] : []
      }]
    });
  }

  private collectDynamicCheckboxData(checkboxList: string[], data: string[]): string[] {
    const arr = [];
    data.forEach((item, index) => {
      if (item) {
        arr.push(checkboxList[index]);
      }
    });
    return arr;
  }

  private buildDynamicCheckboxFormArray(checkboxList: string[], data: string[], isRequired: boolean = true): FormArray {
    const arr = checkboxList.map(category => {
      if (!data) {
        return this.fb.control(false, isRequired ? [Validators.required] : []);
      } else {
        return this.fb.control(data.indexOf(category) >= 0, isRequired ? [Validators.required] : []);
      }
    });
    return this.fb.array(arr, NewLookStep1Component.minSelectedCheckboxes(isRequired));
  }

  get categoriesControls() {
    return this.lookStep1Form.get('categories') as FormArray;
  }

  get dayNightControls() {
    return this.lookStep1Form.get('dayNight') as FormArray;
  }

  public submitForm(): void {
    for (const i in this.lookStep1Form.controls) {
      this.lookStep1Form.controls[i].markAsDirty();
      this.lookStep1Form.controls[i].updateValueAndValidity();
    }

    this.tagsControl.control.markAsDirty();
    this.tagsControl.control.updateValueAndValidity();

    if (this.lookStep1Form.valid) {
      if (this.selectedTagList.length === 0 && BrandRulesManager.isActionAvailable(this.brand, this.rules.reviewTags)) {
        this.makeErrorAlert();
        return;
      }

      this.loadingSubmit = true;
      this.tagsService.addTagsToCollection(this.selectedTagList, this.brand)
        .subscribe((data: ITagItem[]) => {
          const tagIdList = data.reduce((acc, item) => {
            if (acc.indexOf(item._id) === -1) {
              acc.push(item._id);
            }
            return acc;
          }, []);

          this.lookStep1Form.value.categories = this.collectDynamicCheckboxData(this.baseCategories, this.lookStep1Form.value.categories);
          this.lookStep1Form.value.dayNight = this.collectDynamicCheckboxData(this.baseDayNight, this.lookStep1Form.value.dayNight);

          this.loadingSubmit = false;
          this.onSubmitForm.emit({
            step: 1,
            status: 0,
            brand: BrandModelKeys[this.brand],
            influencerRef: this.influencerId,
            ...this.lookStep1Form.value,
            tags: tagIdList
          });
        });
    } else {
      this.makeErrorAlert();
    }
  }

  private makeErrorAlert() {
    this.windowService.scrollTop();
    this.message.error('Something is missing, check below for more info');
  }

  public addNewTag(tagToAdd: string) {
    this.selectedTagList = [...this.selectedTagList, tagToAdd];
    this.tags = this.tags.filter(tag => tag.name !== tagToAdd);
    if (this.tags.length < BrandTagsLimits[this.brand]) {
      this.showMoreTags();
    }
  }

  public onSearch(searchString: string) {
    this.searchTags$.next(searchString);
  }

  public showMoreTags() {
    if (!this.isAllTags) {
      this.loadMoreTags$.next(true);
    }
  }
}
