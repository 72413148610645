import {Component, OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-layout-footer',
  templateUrl: './main-layout-footer.component.html',
  styleUrls: ['./main-layout-footer.component.less']
})
export class MainLayoutFooterComponent implements OnInit {

  baseUrl: string = environment.storeWebAppUrl;

  constructor() {
  }

  ngOnInit() {
  }

}
