import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {BrandRulesManager} from '../helpers/BrandRulesManager';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[accessControl]',
})
export class AccessControlDirective implements OnInit {

  public brand: string;
  public rule: number;
  public else: TemplateRef<any>;

  @Input()
  set accessControl([brand, rule]) {
    this.brand = brand;
    this.rule = rule;
  }

  @Input()
  set accessControlElse(op) {
    this.else = op;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }

  ngOnInit() {
    if (BrandRulesManager.isActionAvailable(this.brand, this.rule)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      if (this.else) {
        this.viewContainer.createEmbeddedView(this.else);
      } else {
        this.viewContainer.clear();
      }
    }
  }
}
