import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';
import {of, Subject, Subscription} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

import {ProductsSelectService} from '../../../services/products-select.service';
import {ProductsOrderSetInterface} from '../../../interfaces/products-order-set.interface';
import {ProductOrderCategoryInterface} from '../../../interfaces/product-order-category.interface';
import {DynamicSelectShadeComponent} from './dynamic-select-shade/dynamic-select-shade.component';
import {BrandService} from '../../../services/brand.service';

@Component({
  selector: 'app-products-selection-form-step1',
  templateUrl: './products-selection-form-step1.component.html',
  styleUrls: [
    './products-selection-form-step1.component.less',
    '../../add-new-look/steps/new-look-step4/new-look-step4.component.less'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ProductsSelectionFormStep1Component implements OnInit, OnDestroy, AfterViewInit {
  private factory: ComponentFactory<DynamicSelectShadeComponent>;
  private errorCategory: string;
  private subscription: Subscription;
  private unsubscribe$ = new Subject();

  @ViewChildren(DynamicSelectShadeComponent, {read: ViewContainerRef}) public containers: QueryList<DynamicSelectShadeComponent>;
  public isLoadingProducts = false;
  public productsSet: ProductsOrderSetInterface[];
  public isFormSubmitted = false;
  public productsPerCategory: { [key: string]: number } = {};
  public hideAddNewShadeButtonPerCategory: { [key: string]: boolean } = {};

  public itemsPerSubcategory: { [key: string]: number } = {};
  public isFormValid = false;
  public productsSKUs: string[] = [];
  private countryCode = 'US';

  constructor(
    private productsSelectService: ProductsSelectService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private brandService: BrandService
  ) {
  }

  ngOnInit() {
    this.isLoadingProducts = true;

    this.subscription = this
      .brandService
      .getCurrentBrand()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(e => of(e === '' ? this.brandService.defaultBrand : e)),
        switchMap(brand => {
          return this.productsSelectService.getProductsList('InfluencerProfileOrder', this.countryCode, brand);
        })
      ).subscribe((e) => {
        this.productsSet = e;
        this.isLoadingProducts = false;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.factory = this.resolver.resolveComponentFactory(DynamicSelectShadeComponent);
  }

  private validateForm(): boolean {
    let isValid = true;
    for (let i = 0; i < this.productsSet.length; i++) {
      if (this.productsPerCategory[this.productsSet[i].category.toLowerCase()] !== this.productsSet[i].allowedProducts) {
        isValid = false;
        this.errorCategory = this.productsSet[i].category;
        break;
      }
    }
    return isValid;
  }

  public submitForm(): void {
    this.isFormSubmitted = true;
    this.isFormValid = this.validateForm();

    if (this.isFormValid) {
      this.productsSelectService.setSKUsSelection(this.productsSKUs);
      this.router.navigate(['address-select'])
        .catch(console.error);
    } else {
      const el = document.getElementById(this.errorCategory);
      el.scrollIntoView({behavior: 'smooth'});
    }
  }

  public createComponent(item: ProductOrderCategoryInterface) {
    const category = item._id.product_category;
    const subcategory = item.subcategory;
    const container = this.containers.find(component => component._data.componentView.component.item.subcategory === subcategory);
    const componentRef = container._data.viewContainer.createComponent(this.factory);
    const componentInstance = (<DynamicSelectShadeComponent>componentRef.instance);

    componentInstance.item = item;
    componentInstance.category = category;
    componentInstance.viewRef = componentRef;
    componentInstance.container = container;
    componentInstance.selectedProducts = this.productsSKUs;

    componentInstance.addNewShadeClicked
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.createComponent(item));

    componentInstance.addProductShadeClicked
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => this.addProduct(data.shade, data.category, item.subcategory));
  }

  public addProduct(selectedItem: string, category: string, subcategory: string): void {
    const index = this.productsSKUs.indexOf(selectedItem);
    subcategory = subcategory.toLowerCase();
    category = category.toLowerCase();
    if (!this.productsPerCategory[category]) {
      this.productsPerCategory[category] = 0;
    }

    if (!this.itemsPerSubcategory[subcategory]) {
      this.itemsPerSubcategory[subcategory] = 0;
    }

    if (index >= 0) {
      this.productsPerCategory[category]--;
      this.itemsPerSubcategory[subcategory]--;
      this.productsSKUs.splice(index, 1);
    } else {
      this.productsPerCategory[category]++;
      this.itemsPerSubcategory[subcategory]++;
      this.productsSKUs.push(selectedItem);
    }
    this.hideAddNewShadeButtonPerCategory[subcategory] = false;
  }

  public addNewShade(item: ProductOrderCategoryInterface) {
    const subcategory = item.subcategory.toLowerCase();
    this.hideAddNewShadeButtonPerCategory[subcategory] = true;
    this.createComponent(item);
  }
}
