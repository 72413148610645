import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ISignUpResult} from 'amazon-cognito-identity-js';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {from, throwError} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {countriesData} from '../../../services/countries-data.provider';
import {RegistrationService} from '../../../services/registration.service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.less']
})
export class SignUpComponent implements OnInit {
  private passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  private phoneRegex = '[0-9]{10}';

  public influencerId: string;
  public step = 1;
  public signupForm: FormGroup;
  public signupDetailsForm: FormGroup;
  public passwordVisible = false;
  public selectedEmail: string;
  public selectedPassword: string;
  public countries: { name: string }[];
  public errorMsg: string;
  public loading: boolean;
  public profileDataLoading: boolean;
  public token: string;
  public isTokenValid = true;

  constructor(private fb: FormBuilder, private registrationService: RegistrationService, private router: Router,
              @Inject(LOCAL_STORAGE) private storage: StorageService, private route: ActivatedRoute) {
    this.initForm();
  }

  ngOnInit() {
    const emailFromStorage = this.storage.get(environment.signUpStorageKey);
    if (this.storage.get(environment.signUpStorageKey)) {
      this.selectedEmail = emailFromStorage;
      this.step = 2;
    }
    this.countries = countriesData;

    this.route.queryParams.subscribe(params => {
      this.token = params.token;
    });

  }

  private initForm(): void {
    this.signupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordRegex)]]
    }, {updateOn: 'blur'});

    this.signupDetailsForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      street: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      country: [null, [Validators.required]],
      phoneNumber: ['', [Validators.required, Validators.pattern(this.phoneRegex)]]
    });
  }

  public validate(event: any, field: string) {
    this.signupForm.get(field).setValue(event.target.value);
  }

  public submitSignup(): void {
    for (const i in this.signupForm.controls) {
      this.signupForm.controls[i].markAsDirty();
      this.signupForm.controls[i].updateValueAndValidity();
    }

    if (this.signupForm.valid && !this.loading) {
      this.loading = true;
      this.selectedEmail = this.signupForm.get('email').value.toString().toLowerCase();
      this.selectedPassword = this.signupForm.get('password').value;

      this.registrationService.verifyToken(this.token, this.selectedEmail)
        .pipe(
          switchMap((data) => {
            if (data.valid) {
              this.isTokenValid = true;
              return from(this.registrationService.signupUser({
                username: this.selectedEmail,
                password: this.selectedPassword,
                attributes: {
                  email: this.selectedEmail
                }
              }));
            } else {
              this.isTokenValid = false;
              return throwError(new Error('Invalid Token'));
            }
          })
        )
        .subscribe(
          (data: ISignUpResult) => {
            this.loading = false;
            this.influencerId = data.userSub;
            this.storage.set(environment.signUpStorageKey, this.selectedEmail);
            window.dataLayer.push({event: 'signup-email-password'});
            this.nextStep();
          },
          err => {
            this.loading = false;
            this.errorMsg = err.message;
          });
    }
  }

  public submitDetailsSignup(): void {
    for (const i in this.signupDetailsForm.controls) {
      this.signupDetailsForm.controls[i].markAsDirty();
      this.signupDetailsForm.controls[i].updateValueAndValidity();
    }

    if (this.signupDetailsForm.valid) {
      this.profileDataLoading = true;
      this.registrationService.updateInfluencerRegistrationData(this.influencerId, this.signupDetailsForm.value)
        .subscribe(() => {
          window.dataLayer.push({event: 'signup-impact'});
          this.profileDataLoading = false;
          this.router.navigate([`/terms-conditions/${this.influencerId}`])
            .catch(err => console.error(err));
        });
    }
  }

  public nextStep(): void {
    this.step += 1;
  }

  public openStore() {
    window.open('https://www.ilmakiage.com', '_blank');
  }

}
