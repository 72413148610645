import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {AppState} from '../../../state/state';
import {Store} from '@ngrx/store';
import {BrandService} from '../../../services/brand.service';
import {RULE_ALLOW_EDIT_LOOKS, RULE_ALLOW_EDIT_REVIEWS} from '../../../helpers/BrandRulesManager';
import {BrandEnum} from '@enums';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.less']
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  public readonly rules = {
    showReview: RULE_ALLOW_EDIT_REVIEWS,
    showLooks: RULE_ALLOW_EDIT_LOOKS
  };
  public isButtonShown = true;
  public isPreloaderShown = false;
  public brand: string = BrandEnum['Il Makiage'];
  private appState$: Observable<AppState>;
  private subscription = new Subscription();

  constructor(
    private router: Router,
    private store: Store<{ app: AppState }>,
    private brandService: BrandService
  ) {
  }

  ngOnInit() {
    this.appState$ = this.store.select('app');

    this.subscription.add(this.appState$.subscribe((appState: AppState) => {
      setTimeout(() => {
        this.isButtonShown = appState.isAddItemShown;
        this.isPreloaderShown = appState.isPreloaderShown;
      });
    }));

    this.subscription.add(
      this.brandService
        .getCurrentBrand()
        .pipe(distinctUntilChanged())
        .subscribe(e => {
          this.brand = e === '' ? this.brandService.defaultBrand : e;
        })
    );
  }

  public navigateTo(url: string) {
    this.router.navigate([url])
      .catch(err => console.error(err));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
