import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {RegistrationService} from '../../../services/registration.service';
import {MyProfileService} from '../../../services/my-profile.service';
import {UserRoleEnum} from '@enums';
import {BrandService} from '../../../services/brand.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/state';
import {setInfluencer} from '../../../state/actions';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.less']
})
export class SignInComponent implements OnInit {
  private username: string;
  public errorMsg: string;
  public signinForm: FormGroup;
  public passwordVisible = false;
  public loading: boolean;
  public route: string;

  constructor(
    private fb: FormBuilder,
    private registrationService: RegistrationService,
    private router: Router,
    private brandService: BrandService,
    private myProfileService: MyProfileService,
    private store: Store<{ app: AppState }>
  ) {
    this.initForm();
  }

  ngOnInit() {
  }

  private initForm() {
    this.signinForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    }, {updateOn: 'blur'});
  }

  public validate(event: any, field: string) {
    this.signinForm.get(field).setValue(event.target.value);
  }

  public submitSignin(): void {
    for (const i in this.signinForm.controls) {
      this.signinForm.controls[i].markAsDirty();
      this.signinForm.controls[i].updateValueAndValidity();
    }

    const availableLookPath = ['/my-looks', '/my-reviews'];

    if (this.signinForm.valid && !this.loading) {
      this.loading = true;
      this.username = this.signinForm.get('email').value.toString().toLowerCase();

      this.registrationService.signinUser(this.username, this.signinForm.get('password').value)
        .then(() => this.registrationService.getCurrentUserInfo())
        .then((data: any) => {
          this.route = data.attributes && data.attributes['custom:role'] === UserRoleEnum.Admin ? '/dashboard' : '/my-looks';
          return this.myProfileService.getInfluencerProfileData(data.attributes.sub).toPromise();
        })
        .then((profile) => {
          this.loading = false;
          if (profile && (availableLookPath.indexOf(this.route) !== -1)) {
            this.store.dispatch(setInfluencer({influencer: profile}));
            if (profile.brand.length > 1) {
              this.route = '/brands';
            } else if (profile.brand.length === 1) {
              this.route = profile.brand[0] === this.brandService.defaultBrand ? '/my-looks' : '/my-reviews';
            }
          }
          this.router.navigate([this.route]).catch(err => console.error(err));
        })

        .catch(err => {
          this.loading = false;
          this.errorMsg = err.message;
        });
    }
  }

}
