import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import en from '@angular/common/locales/en';
import {ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import Auth from '@aws-amplify/auth';
import {DigitOnlyModule} from '@uiowa/digit-only';
import {AmplifyAngularModule, AmplifyModules, AmplifyService} from 'aws-amplify-angular';
import {en_US, NgZorroAntdModule, NZ_I18N} from 'ng-zorro-antd';
import {FileUploadModule} from 'ng2-file-upload';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  AddNewLookCompletedComponent
} from './components/add-new-look/add-new-look-completed/add-new-look-completed.component';
import {AddNewLookContainerComponent} from './components/add-new-look/add-new-look-container.component';
import {NewLookStep1Component} from './components/add-new-look/steps/new-look-step1/new-look-step1.component';
import {
  DynamicVideoTimestampSelectorComponent
} from './components/add-new-look/steps/new-look-step2/dynamic-video-timestamp-selector/dynamic-video-timestamp-selector.component';
import {NewLookStep2Component} from './components/add-new-look/steps/new-look-step2/new-look-step2.component';
import {NewLookStep3Component} from './components/add-new-look/steps/new-look-step3/new-look-step3.component';
import {
  Step3ExamplesComponent
} from './components/add-new-look/steps/new-look-step3/step3-examples/step3-examples.component';
import {
  AddCustomProductComponent
} from './components/add-new-look/steps/new-look-step4/add-custom-product/add-custom-product.component';
import {NewLookStep4Component} from './components/add-new-look/steps/new-look-step4/new-look-step4.component';
import {
  DashboardModalCustomLookComponent
} from './components/dashboard/dashboard-modal-custom-look-component/dashboard-modal-custom-look.component';
import {
  DashboardModalCustomProductComponent
} from './components/dashboard/dashboard-modal-custom-product-component/dashboard-modal-custom-product.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {QuestionTooltipComponent} from './components/dashboard/question-tooltip/question-tooltip.component';
import {EditLookContainerComponent} from './components/edit-look/edit-look-container.component';
import {InviteInfluencersComponent} from './components/invite-influencers/invite-influencers.component';
import {JwplayerComponent} from './components/jwplayer/jwplayer.component';
import {
  InfluencersProductsLayoutHeaderComponent
} from './components/layouts/influencers-products-ordering-layout/influencers-products-layout-header/influencers-products-layout-header.component';
import {
  InfluencersProductsOrderingLayoutComponent
} from './components/layouts/influencers-products-ordering-layout/influencers-products-ordering-layout.component';
import {
  MainLayoutFooterComponent
} from './components/layouts/main-layout/main-layout-footer/main-layout-footer.component';
import {
  MainLayoutHeaderComponent
} from './components/layouts/main-layout/main-layout-header/main-layout-header.component';
import {MainLayoutComponent} from './components/layouts/main-layout/main-layout.component';
import {RegistrationLayoutComponent} from './components/layouts/registration-layout/registration-layout.component';
import {MyLooksComponent} from './components/my-looks/my-looks.component';
import {SingleLookComponent} from './components/my-looks/single-look/single-look.component';
import {MyProfileComponent} from './components/my-profile/my-profile.component';
import {
  DynamicSelectShadeComponent
} from './components/products-selection-form/products-selection-form-step1/dynamic-select-shade/dynamic-select-shade.component';
import {
  ProductsSelectionFormStep1Component
} from './components/products-selection-form/products-selection-form-step1/products-selection-form-step1.component';
import {
  ProductsSelectionFormStep2Component
} from './components/products-selection-form/products-selection-form-step2/products-selection-form-step2.component';
import {ForgotPasswordComponent} from './components/registration/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/registration/reset-password/reset-password.component';
import {SignInComponent} from './components/registration/sign-in/sign-in.component';
import {SignUpComponent} from './components/registration/sign-up/sign-up.component';
import {
  VerificationCodeComponent
} from './components/registration/sign-up/verification-code/verification-code.component';
import {
  TermsAndConditionsComponent
} from './components/registration/terms-and-conditions/terms-and-conditions.component';
import {DynamicVideoTimestampsAnchorDirective} from './directives/dynamic-video-timestamps-anchor.directive';
import {AuthInterceptor} from './interceptors/auth-interceptor';
import {FileSizePipe} from './pipes/file-size.pipe';
import {LimitPipe} from './pipes/limit.pipe';
import {LookStatusDisplayTextPipe} from './pipes/look-status-display-text.pipe';
import {AuthUserResolver} from './resolvers/auth-user-resolver';
import {LoggedInUserCheckResolver} from './resolvers/logged-in-user-check.resolver';
import {ResetPasswordResolver} from './resolvers/reset-password-resolver';
import {SentryErrorHandlerService} from './services/sentry-error-handler.service';
import {CarouselsEditingComponent} from './components/carousels-editing/carousels-editing.component';
import {BrandsComponent} from './components/brands/brands.component';
import {AddNewComponent} from './components/layouts/add-new/add-new.component';
import {VarDirective} from './directives/var.directive';
import {AccessControlDirective} from './directives/access-control.directive';
import {RotationPickerComponent} from './components/shared/rotation-picker/rotation-picker.component';
import {StoreModule} from '@ngrx/store';
import {appReducer} from './state/app.reducer';
import {BrandSwitcherComponent} from './components/brand-switcher/brand-switcher.component';
import {SignUpRequestComponent} from './components/registration/sign-up-request/sign-up-request.component';


registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutHeaderComponent,
    MainLayoutFooterComponent,
    AddNewLookContainerComponent,
    NewLookStep1Component,
    NewLookStep2Component,
    NewLookStep3Component,
    NewLookStep4Component,
    AddNewLookCompletedComponent,
    EditLookContainerComponent,
    MyLooksComponent,
    DashboardComponent,
    FileSizePipe,
    LimitPipe,
    LookStatusDisplayTextPipe,
    AddCustomProductComponent,
    SingleLookComponent,
    MyProfileComponent,
    QuestionTooltipComponent,
    MainLayoutComponent,
    InfluencersProductsOrderingLayoutComponent,
    InfluencersProductsLayoutHeaderComponent,
    ProductsSelectionFormStep1Component,
    ProductsSelectionFormStep2Component,
    DynamicSelectShadeComponent,
    SignInComponent,
    SignUpComponent,
    VerificationCodeComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    JwplayerComponent,
    RegistrationLayoutComponent,
    DashboardModalCustomLookComponent,
    DashboardModalCustomProductComponent,
    DynamicVideoTimestampSelectorComponent,
    DynamicVideoTimestampsAnchorDirective,
    Step3ExamplesComponent,
    InviteInfluencersComponent,
    TermsAndConditionsComponent,
    CarouselsEditingComponent,
    BrandsComponent,
    AddNewComponent,
    VarDirective,
    AccessControlDirective,
    RotationPickerComponent,
    BrandSwitcherComponent,
    SignUpRequestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgZorroAntdModule,
    FileUploadModule,
    AmplifyAngularModule,
    DigitOnlyModule,
    StoreModule.forRoot({app: appReducer})
  ],
  providers: [
    {provide: NZ_I18N, useValue: en_US},
    {provide: ErrorHandler, useClass: SentryErrorHandlerService},
    {
      provide: AmplifyService, useFactory: () => {
        return AmplifyModules({Auth});
      }
    },
    AuthUserResolver,
    LoggedInUserCheckResolver,
    ResetPasswordResolver,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  entryComponents: [
    DynamicSelectShadeComponent,
    DashboardModalCustomLookComponent,
    DashboardModalCustomProductComponent,
    DynamicVideoTimestampSelectorComponent,
    Step3ExamplesComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
