import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {from} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {environment} from '../../../../../environments/environment';
import {RegistrationService} from '../../../../services/registration.service';
import {InviteInfluencersService} from '../../../../services/invite-influencers.service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.less']
})
export class VerificationCodeComponent implements OnInit {
  @Input() email: string;
  @Input() password: string;
  @Input() token: string;
  @Input() influencerId: string;
  @Output() submittedCodeVerification = new EventEmitter<boolean>();
  public codeVerifyForm: FormGroup;
  public codeVerifyErrorMsg: string;
  public resendCodeLoading = false;
  public loading: boolean;

  constructor(
    private fb: FormBuilder,
    private registrationService: RegistrationService,
    private inviteInfluencersService: InviteInfluencersService,
    @Inject(LOCAL_STORAGE) private storage: StorageService) {
    this.initForm();
  }

  ngOnInit() {
  }

  private initForm(): void {
    this.codeVerifyForm = this.fb.group({
      codeInput: [null, [Validators.required]]
    });
  }

  public submitCode(): void {
    const codeToSubmit = this.codeVerifyForm.value && this.codeVerifyForm.value.codeInput;
    this.loading = true;
    from(this.registrationService.submitSignupCodeVerification(this.email, codeToSubmit))
      .pipe(
        switchMap(() => from(this.registrationService.signinUser(this.email, this.password))),
        switchMap(() => this.registrationService.createInfluencer(this.email, this.influencerId)),
        switchMap(() => this.inviteInfluencersService.updateInvite(this.token, {isUsed: true})),
      )
      .subscribe(() => {
          this.loading = false;
          this.storage.remove(environment.signUpStorageKey);
          this.submittedCodeVerification.emit(true);
          window.dataLayer.push({'event': 'signup-verification-code'});
        },
        err => {
          this.loading = false;
          this.codeVerifyErrorMsg = err.message;
        });
  }

  public resendCode(): void {
    if (this.email) {
      this.resendCodeLoading = true;
      from(this.registrationService.resendConfirmationCode(this.email))
        .subscribe(
          () => setTimeout(() => this.resendCodeLoading = false, 5000),
          err => {
            this.codeVerifyErrorMsg = err.message && err.message.replace(', ', ', <br />');
            this.resendCodeLoading = false;
          }
        );
    }
  }

}
