import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomProductItemInterface} from '../../../../../interfaces/custom-product-item.interface';

@Component({
  selector: 'app-add-custom-product',
  templateUrl: './add-custom-product.component.html',
  styleUrls: ['./add-custom-product.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AddCustomProductComponent implements OnInit {
  @Input() private lookId: string;
  @Input() private influencerId: string;
  @Input() public section: string;
  @Output() public customProduct = new EventEmitter<CustomProductItemInterface>();

  public customProductFormToggle = false;
  public customProductForm = {
    eyes: FormGroup,
    lips: FormGroup,
    face: FormGroup,
    brows: FormGroup
  };

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.customProductForm[this.section] = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(64)]],
      brandName: [null, [Validators.required, Validators.maxLength(64)]],
      color: [null, [Validators.required, Validators.maxLength(64)]],
    }, {updateOn: 'blur'});
  }

  public resetCustomProductForm(form: FormGroup): void {
    form.reset();
    this.customProductFormToggle = !this.customProductFormToggle;
  }

  public submitCustomProduct(form: FormGroup): void {
    for (const i in this.customProductForm[this.section].controls) {
      this.customProductForm[this.section].controls[i].markAsDirty();
      this.customProductForm[this.section].controls[i].updateValueAndValidity();
    }

    if (this.customProductForm[this.section].valid) {
      const payload: CustomProductItemInterface = {
        ...form.value,
        category: this.section
      };

      this.customProduct.emit(payload);
      this.customProductFormToggle = false;
      form.reset();
    }
  }

}
