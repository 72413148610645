import {AfterViewInit, Component, Input} from '@angular/core';

declare const jwplayer: any;

@Component({
  selector: 'app-jwplayer',
  templateUrl: './jwplayer.component.html',
  styleUrls: ['./jwplayer.component.less']
})
export class JwplayerComponent implements AfterViewInit {
  @Input() public domID: string;
  @Input() public videoUrl: string;
  @Input() public imageUrl: string;
  public isVideoPlaying = false;

  constructor() {
  }

  ngAfterViewInit(): void {
    jwplayer(this.domID)
      .setup({
        file: this.videoUrl,
        image: this.imageUrl,
        width: '100%',
        aspectratio: '1:1'
      })
      .on('play', () => {
        this.isVideoPlaying = true;
      });
  }

  stopVideo() {
    this.isVideoPlaying = false;
    jwplayer(this.domID).pause(true);
  }
}
