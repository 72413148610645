import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthUserResolver} from './resolvers/auth-user-resolver';
import {LoggedInUserCheckResolver} from './resolvers/logged-in-user-check.resolver';
import {ResetPasswordResolver} from './resolvers/reset-password-resolver';
import {OnlyAdminsGuard} from './guards/only-admins-guard.service';
import {OnlyAdminAndContentEditorGuard} from './guards/only-admin-and-content-editor.guard';
import {NotContentEditorGuard} from './guards/not-content-editor.guard';
import {ProductsSelectSubmitGuard} from './guards/products-select-submit.guard';
import {AddNewLookContainerComponent} from './components/add-new-look/add-new-look-container.component';
import {AddNewLookCompletedComponent} from './components/add-new-look/add-new-look-completed/add-new-look-completed.component';
import {MyLooksComponent} from './components/my-looks/my-looks.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {MyProfileComponent} from './components/my-profile/my-profile.component';
import {MainLayoutComponent} from './components/layouts/main-layout/main-layout.component';
import {InfluencersProductsOrderingLayoutComponent} from './components/layouts/influencers-products-ordering-layout/influencers-products-ordering-layout.component';
import {ProductsSelectionFormStep1Component} from './components/products-selection-form/products-selection-form-step1/products-selection-form-step1.component';
import {ProductsSelectionFormStep2Component} from './components/products-selection-form/products-selection-form-step2/products-selection-form-step2.component';
import {EditLookContainerComponent} from './components/edit-look/edit-look-container.component';
import {SignInComponent} from './components/registration/sign-in/sign-in.component';
import {ForgotPasswordComponent} from './components/registration/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/registration/reset-password/reset-password.component';
import {RegistrationLayoutComponent} from './components/layouts/registration-layout/registration-layout.component';
import {InviteInfluencersComponent} from './components/invite-influencers/invite-influencers.component';
import {TermsAndConditionsComponent} from './components/registration/terms-and-conditions/terms-and-conditions.component';
import {CarouselsEditingComponent} from './components/carousels-editing/carousels-editing.component';
import {BrandsComponent} from './components/brands/brands.component';
import {LookItemResolver} from './resolvers/look-item.resolver';
import {ReviewItemResolver} from './resolvers/review-item.resolver';
import {SignUpRequestComponent} from "./components/registration/sign-up-request/sign-up-request.component";

const routes: Routes = [
  {
    path: 'main-page',
    redirectTo: 'sign-up'
  },
  {path: '', redirectTo: '/sign-in', pathMatch: 'full'},
  {
    path: '',
    component: RegistrationLayoutComponent,
    children: [
      {
        path: 'sign-in', component: SignInComponent,
        resolve: {isLogged: LoggedInUserCheckResolver}, runGuardsAndResolvers: 'always'
      },
      {
        path: 'sign-up', component: SignUpRequestComponent,
        resolve: {isLogged: LoggedInUserCheckResolver}, runGuardsAndResolvers: 'always'
      },
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {
        path: 'reset-password', component: ResetPasswordComponent,
        resolve: {haveEmail: ResetPasswordResolver}, runGuardsAndResolvers: 'always'
      },
      {path: 'terms-conditions/:influencerId', component: TermsAndConditionsComponent},
    ]
  },
  {
    path: '',
    component: InfluencersProductsOrderingLayoutComponent,
    children: [
      {path: 'products-select', component: ProductsSelectionFormStep1Component},
      {path: 'address-select', component: ProductsSelectionFormStep2Component, canActivate: [ProductsSelectSubmitGuard]}
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    resolve: {
      userData: AuthUserResolver
    },
    runGuardsAndResolvers: 'always',
    children: [
      {path: 'dashboard', component: DashboardComponent, canActivate: [NotContentEditorGuard], runGuardsAndResolvers: 'always'},
      {path: 'congratulations', component: AddNewLookCompletedComponent},
      {
        path: 'my-looks', component: MyLooksComponent
      },
      {
        path: 'my-reviews', component: MyLooksComponent
      },
      {path: 'my-profile', component: MyProfileComponent},
      {path: 'add-new-look', component: AddNewLookContainerComponent},
      {
        path: 'edit-look/:influencerId/:entryId', component: EditLookContainerComponent, resolve: {
          entry: LookItemResolver
        }
      },
      {path: 'add-new-review', component: AddNewLookContainerComponent},
      {
        path: 'edit-review/:influencerId/:entryId', component: EditLookContainerComponent, resolve: {
          entry: ReviewItemResolver
        }
      },
      {path: 'invite-influencers', component: InviteInfluencersComponent, canActivate: [OnlyAdminsGuard]},
      {path: 'carousels-editing', component: CarouselsEditingComponent, canActivate: [OnlyAdminAndContentEditorGuard]},
      {path: 'brands', component: BrandsComponent},
      {
        path: 'reporting-tool',
        loadChildren: './modules/reporting-tool/reporting-tool.module#ReportingToolModule',
        canActivate: [OnlyAdminsGuard]
      },
      {
        path : 'add-users',
        loadChildren: './modules/add-users/add-users.module#AddUsersModule',
      }
    ]
  },
  {path: '**', redirectTo: 'sign-in'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
