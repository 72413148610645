import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {InviteInfluencersService} from '../../services/invite-influencers.service';
import {InfluencerInviteInterface} from '../../interfaces/influencer-invite.interface';


@Component({
  selector: 'app-invite-influencers',
  templateUrl: './invite-influencers.component.html',
  styleUrls: ['./invite-influencers.component.less']
})
export class InviteInfluencersComponent implements OnInit {

  public showCopyLinkMsg = false;
  public inviteForm: FormGroup;
  public loading: boolean;
  public invites$: Observable<InfluencerInviteInterface[]>;
  public linkToCopy: string;

  constructor(private fb: FormBuilder, private inviteService: InviteInfluencersService) {
  }

  ngOnInit() {
    this.initForm();
    this.invites$ = this.inviteService.getAllInvites();
  }

  private initForm() {
    this.inviteForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]
    }, {updateOn: 'blur'});
  }

  public submitInvite(): void {
    for (const i in this.inviteForm.controls) {
      this.inviteForm.controls[i].markAsDirty();
      this.inviteForm.controls[i].updateValueAndValidity();
    }

    if (this.inviteForm.valid && !this.loading) {
      this.loading = true;
      this.inviteService.generateToken(this.inviteForm.value)
        .subscribe(
          (data: InfluencerInviteInterface) => {
            this.loading = false;
            this.showCopyLinkMsg = true;
            this.linkToCopy = data.link;
          },
          err => {
            this.loading = false;
            console.log(err);
          });
    }
  }

  public generateAnotherLink() {
    this.showCopyLinkMsg = false;
    this.inviteForm.reset();
  }

  public getInviteStatus(data: InfluencerInviteInterface): string {
    if (data.isValid) {
      if (data.isUsed) {
        return 'Confirmed';
      }

      if (new Date(data.expiration).getTime() <= new Date().getTime()) {
        return 'Link expired';
      } else {
        return 'Pending';
      }
    } else {
      return 'Invalid';
    }
  }

}
