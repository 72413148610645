import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {ProductsSelectService} from '../services/products-select.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsSelectSubmitGuard implements CanActivate {

  constructor(private productsSelectService: ProductsSelectService, private router: Router) {
  }

  canActivate(): UrlTree | boolean {

    if (this.productsSelectService.getSKUsSelection().length) {
      return true;
    } else {
      return this.router.parseUrl('/products-select');
    }
  }
}
