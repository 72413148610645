import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export const TYPE_0_DEGREE = 1;
export const TYPE_90_DEGREE = 2;
export const TYPE_180_DEGREE = 3;
export const TYPE_270_DEGREE = 4;

export interface RotateListInterface {
  [key: number]: { value: number, name: string };
}

export const ROTATE_TYPES: RotateListInterface = {
  [TYPE_0_DEGREE]: {
    value: 0,
    name: '0 deg'
  },
  [TYPE_90_DEGREE]: {
    value: 90,
    name: '90 deg'
  },
  [TYPE_180_DEGREE]: {
    value: 180,
    name: '180 deg'
  },
  [TYPE_270_DEGREE]: {
    value: 270,
    name: '270 deg'
  }
};

@Component({
  selector: 'app-rotation-picker',
  templateUrl: './rotation-picker.component.html',
  styleUrls: ['./rotation-picker.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RotationPickerComponent),
    multi: true
  }]
})
export class RotationPickerComponent implements OnInit, ControlValueAccessor {

  readonly rotates: RotateListInterface = ROTATE_TYPES;
  public isRotationBlockOpened = false;
  public selectedId: number = TYPE_0_DEGREE;
  @Input()
  public title: string = null;
  @Output() change = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit() {
  }

  set value(value) {
    this.selectedId = value;
  }

  get value(): number {
    return this.selectedId;
  }

  public toggleRotationBlock() {
    this.isRotationBlockOpened = !this.isRotationBlockOpened;
  }

  public changeRotation(rotateKey: number) {
    this.selectedId = rotateKey;

    this.writeValue(this.selectedId);
    this.updateRotation();
  }

  changed = (_: any) => {
  };

  touched = (_: any) => {
  };

  public onTouched: any = () => {
  };

  public registerOnChange(fn: any) {
    this.changed = fn;
  }

  public registerOnTouched(fn: any) {
    this.touched = fn;
  }

  writeValue(value: any): void {
  }

  public updateRotation() {
    const value = ROTATE_TYPES[this.selectedId].value;
    this.changed(value);
    this.change.emit(value);
  }
}
