import {Component, ComponentRef, EventEmitter, Input, Output} from '@angular/core';
import {ProductOrderCategoryInterface} from '../../../../interfaces/product-order-category.interface';
import {SelectedShadeInterface} from '../../../../interfaces/selected-shade.interface';

@Component({
  selector: 'app-dynamic-select-shade',
  templateUrl: './dynamic-select-shade.component.html',
  styleUrls: ['./dynamic-select-shade.component.less']
})
export class DynamicSelectShadeComponent {
  private lastAddedItem: string;

  public _data: any;
  public selectedItem: string;
  public isAdded = false;
  public container: DynamicSelectShadeComponent;
  @Input() category: string;
  @Input() item: ProductOrderCategoryInterface;
  @Input() isFirst: boolean;
  @Input() viewRef: ComponentRef<DynamicSelectShadeComponent>;
  @Input() selectedProducts: string[];
  @Output() addProductShadeClicked: EventEmitter<SelectedShadeInterface> = new EventEmitter<SelectedShadeInterface>();
  @Output() addNewShadeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public addProduct(shade: string): void {
    this.isAdded = !this.isAdded;
    if (this.isAdded) {
      this.lastAddedItem = shade;
    }
    this.addProductShadeClicked.emit({shade, category: this.category});
  }

  public removeProduct(shade: string): void {
    this.addProductShadeClicked.emit({shade, category: this.category});
    this.viewRef.destroy();
  }

  public shadeChange(value) {
    if (this.isAdded && this.lastAddedItem !== value) {
      this.addProductShadeClicked.emit({shade: this.lastAddedItem, category: this.category});
      this.addProductShadeClicked.emit({shade: value, category: this.category});
      this.lastAddedItem = value;
    }
  }

}
