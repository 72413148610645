import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {of, Subject} from 'rxjs';
import {filter, switchMap, takeUntil} from 'rxjs/operators';
import {NewLookService} from '../../services/new-look.service';
import {MyProfileService} from '../../services/my-profile.service';
import {IUserAuthResolveInterface} from '../../interfaces/user-auth-resolve.interface';
import {InfluencerProfileInterface} from '../../interfaces/influencer-profile.interface';
import {AdminInfluencersService} from '../../services/admin-influencers.service';
import {WindowService} from '../../services/window.service';
import {BrandService} from '../../services/brand.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/state';
import {setAddItemHidden, setAddItemShown} from '../../state/actions';
import {BrandEnum, BrandLogosHeader, BrandLogoSizesClass} from '@enums';
import {RULE_SHOW_LOOK_STEP, RULE_SHOW_REVIEW_STEP} from '../../helpers/BrandRulesManager';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-add-new-look-container',
  templateUrl: './add-new-look-container.component.html',
  styleUrls: ['./add-new-look-container.component.less']
})
export class AddNewLookContainerComponent implements OnInit, OnDestroy {

  public readonly rules = {
    showLookStep: RULE_SHOW_LOOK_STEP,
    showReviewStep: RULE_SHOW_REVIEW_STEP
  };
  public readonly brandLogos = BrandLogosHeader;
  public readonly brandLogoSizeClass = BrandLogoSizesClass;
  public newLookPayload: any = {};
  public influencerId: string;
  public current = 0;
  public lastStep = 0;
  public isAdmin: boolean;
  public isContentEditor: boolean;
  public submitLoading = false;
  public brand: string = BrandEnum['Il Makiage'];
  public styling: {
    isReview: boolean
  } = {
    isReview: false
  };
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private newLookService: NewLookService,
    private route: ActivatedRoute,
    private myProfileService: MyProfileService,
    private adminInfluencersService: AdminInfluencersService,
    private windowService: WindowService,
    private brandService: BrandService,
    private store: Store<{ app: AppState }>
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(setAddItemHidden());
    const userData: IUserAuthResolveInterface = this.route.snapshot.parent.data.userData;
    this.influencerId = userData.influencerId;
    this.isAdmin = userData.isAdmin;
    this.isContentEditor = (userData && userData.role === 'content_editor');

    this.adminInfluencersService.getNewInfluencerCall()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.influencerId = data.influencerId;
        this.getInfluencerProfileData(userData.role);
      });

    this.getInfluencerProfileData(userData.role);
    this.subscribeToBrandChange();
  }

  private subscribeToBrandChange() {
    this.brandService
      .getCurrentBrand()
      .pipe(
        takeUntil(this.destroy$),
        switchMap(brand => of((brand === '' ? this.brandService.defaultBrand : brand)))
      )
      .subscribe(brand => {
        this.brand = brand;
        this.calculateStyling(brand);
      });
  }

  private calculateStyling(brand: string) {
    this.styling.isReview = brand !== this.brandService.defaultBrand;
  }

  ngOnDestroy() {
    this.store.dispatch(setAddItemShown());
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private getInfluencerProfileData(role: string) {
    this.myProfileService
      .getInfluencerProfileData(this.influencerId)
      .pipe(
        filter(e => !!e)
      )
      .subscribe((profile: InfluencerProfileInterface) => {
        this.newLookPayload.countryCode = profile.country.toLowerCase();
        if (role === 'influencer' && (!profile || !profile.isProfileCompleted)) {
          this.router
            .navigate(['/my-profile'], {state: {isProfileCompleted: false}})
            .catch((err) => console.log(err));
        }
      });
  }

  public setStep(step: number, isFormSubmit = false): void {
    if (isFormSubmit || step <= this.lastStep) {
      if (this.lastStep < step) {
        this.lastStep = step;
      }
      this.current = step;
      this.windowService.scrollIntoViewByObjectId('scroll-top', 'smooth');
    }
  }

  public formSubmit(data: any): void {
    this.submitLoading = true;
    this.newLookPayload = {...this.newLookPayload, ...data};

    this.newLookService.upsertNewLook(this.newLookPayload)
      .subscribe((results: any) => {
        this.submitLoading = false;
        if (!this.newLookPayload._id && results._id) {
          this.newLookPayload._id = results._id;
        }

        if (data.step === 4) {
          this.windowService.dataLayerPush({event: `create-look-success`});
          this.router.navigate(['/congratulations'])
            .catch((err) => console.log(err));
        } else {
          this.windowService.dataLayerPush({event: `create-look-step${data.step}`});
          this.windowService.scrollIntoViewByObjectId('scroll-top', 'smooth');
          this.setStep(data.step, true);
        }
      });
  }
}
