import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProductsSelectService} from '../../../services/products-select.service';

@Component({
  selector: 'app-products-selection-form-step2.component',
  templateUrl: './products-selection-form-step2.component.html',
  styleUrls: ['./products-selection-form-step2.component.less']
})
export class ProductsSelectionFormStep2Component implements OnInit {

  public isVisibleModal = false;
  public isFormSubmitted = false;
  public disableSubmit = false;
  public productsAddressForm: FormGroup;
  public productsSKUs: string[];
  private ilMakiageUrl = 'https://www.ilmakiage.com/';

  constructor(private fb: FormBuilder, private productsSelectService: ProductsSelectService) {
  }

  ngOnInit() {
    this.initForm();
    this.productsSKUs = this.productsSelectService.getSKUsSelection();
    window.scrollTo(0, 0);
  }

  public submitForm() {
    this.isFormSubmitted = !this.isFormSubmitted;
    this.productsSelectService.submitSelectedProducts(this.productsAddressForm)
      .subscribe(() => {
        this.disableSubmit = true;
        this.isVisibleModal = true;
        (<any>window).ga('send', 'event', 'done process', '', '');
      });
  }

  public navigateOut(): void {
    window.open(this.ilMakiageUrl, '_self');
  }

  private initForm(): void {
    this.productsAddressForm = this.fb.group({
      fullname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      address: ['', [Validators.required]],
      apt: ['', []],
      city: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      state: ['', []],
      country: ['', [Validators.required]]
    });
  }

}
