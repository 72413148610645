import {Component, OnInit} from '@angular/core';
import {NzCarouselComponent} from 'ng-zorro-antd';
import {BrandService} from '../../../../../services/brand.service';
import {of, Subscription} from 'rxjs';
import {distinctUntilChanged, switchMap} from 'rxjs/operators';
import {BrandEnum, BrandLogoSizesClass} from '@enums';

declare let require: any;
const shootExamples = require('../../../../../stubs/shoot-examples.json');

export interface ShootExampleItem {
  type: string;
  name: string;
  style: {[key: string]: string};
  images: Array<{src: string}>;
}

@Component({
  selector: 'app-step3-examples',
  templateUrl: './step3-examples.component.html',
  styleUrls: ['./step3-examples.component.less']
})
export class Step3ExamplesComponent implements OnInit {

  public shootExamples: {title: string, items: Array<ShootExampleItem>} = null;
  public brand: string = BrandEnum['Il Makiage'];
  private subscription = new Subscription();

  constructor(private brandService: BrandService) {
  }

  ngOnInit() {
    this.subscribeOnBrandChange();
  }

  private subscribeOnBrandChange = () => {
    this.subscription.add( this.brandService.getCurrentBrand()
      .pipe(
        distinctUntilChanged(),
        switchMap(brand => {
          return of(brand === '' ? this.brandService.defaultBrand : brand);
        })
      )
      .subscribe(brand => {
        this.brand = brand;

        this.shootExamples = shootExamples[BrandLogoSizesClass[this.brand]];
      })
    );
  }
}
