import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'ngx-custom-validators';
import {ActivatedRoute, Router} from '@angular/router';
import {from} from 'rxjs';
import {RegistrationService} from '../../../services/registration.service';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent implements OnInit {
  private passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

  public resetPasswordForm: FormGroup;
  public passwordVisible = false;
  public retypePasswordVisible = false;
  public codeVerifyErrorMsg: string;
  public email: string;
  public loading: boolean;

  constructor(
    private fb: FormBuilder,
    private registrationService: RegistrationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService) {
    this.initForm();
  }

  ngOnInit() {
    this.email = this.storage.get(environment.signUpStorageKey);
  }

  private initForm() {
    const password = new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordRegex)]);
    const retypePassword = new FormControl('', CustomValidators.equalTo(password));

    this.resetPasswordForm = this.fb.group({
      password: password,
      retypePassword: retypePassword,
      codeInput: [null, [Validators.required]]
    });
  }

  public submitForgotPassword() {
    for (const i in this.resetPasswordForm.controls) {
      this.resetPasswordForm.controls[i].markAsDirty();
      this.resetPasswordForm.controls[i].updateValueAndValidity();
    }

    if (this.resetPasswordForm.valid) {
      this.loading = true;
      const confirmationCode = this.resetPasswordForm.value && this.resetPasswordForm.value.codeInput;

      from(this.registrationService.submitForgotPasswordCodeVerification(
        this.storage.get(environment.signUpStorageKey),
        confirmationCode,
        this.resetPasswordForm.get('password').value
      ))
        .subscribe(
          () => {
            this.loading = false;
            this.storage.remove(environment.signUpStorageKey);
            this.router.navigate(['/sign-in'])
              .catch(err => console.error(err));
          },
          err => {
            this.loading = false;
            this.codeVerifyErrorMsg = err.message;
          });
    }
  }
}
