import {Component, OnDestroy, OnInit} from '@angular/core';
import {BrandService} from '../../../services/brand.service';
import {RULE_ALLOW_EDIT_LOOKS, RULE_ALLOW_EDIT_REVIEWS} from '../../../helpers/BrandRulesManager';
import {BrandEnum} from '@enums';
import {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-add-new-look-completed',
  templateUrl: './add-new-look-completed.component.html',
  styleUrls: ['./add-new-look-completed.component.less']
})
export class AddNewLookCompletedComponent implements OnInit, OnDestroy {

  readonly rules = {
    showReview: RULE_ALLOW_EDIT_REVIEWS,
    showLooks: RULE_ALLOW_EDIT_LOOKS,
  };
  public brand: string = BrandEnum['Il Makiage'];
  private subscription = new Subscription();

  constructor(
    private brandService: BrandService
  ) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.subscription.add(
      this
        .brandService
        .getCurrentBrand()
        .pipe(distinctUntilChanged())
        .subscribe(e => {
          this.brand = this.brand === '' || this.brand === null ? BrandEnum['Il Makiage'] : BrandEnum['SpoiledChild'];
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
