import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, Observable, Subject} from 'rxjs';
import {LookItemInterface} from '../../interfaces/look-item.interface';
import {MyLooksService} from '../../services/my-looks.service';
import {IUserAuthResolveInterface} from '../../interfaces/user-auth-resolve.interface';
import {AdminInfluencersService} from '../../services/admin-influencers.service';
import {BrandService} from '../../services/brand.service';
import {distinctUntilChanged, filter, map, takeUntil, tap} from 'rxjs/operators';
import {BrandEnum, BrandLogos, BrandLogosStyle, BrandModelKeys, BrandTypeRelation} from '../../enums/brand.enum';
import {LookCardStylingInterface} from '../../interfaces/look-card-styling.interface';
import {WindowService} from '../../services/window.service';

@Component({
  selector: 'app-my-looks',
  templateUrl: './my-looks.component.html',
  styleUrls: ['./my-looks.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MyLooksComponent implements OnInit, OnDestroy {
  private influencerId: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public activeLooksCount$: Observable<{ count: number }>;
  public isAdmin: boolean;
  public isContentEditor: boolean;
  public readonly playerIdPrefix = 'player';
  public myLooksData$: Observable<LookItemInterface[]>;
  public draftLooksCount$: Observable<{ count: number }>;
  public brand$: Subject<string> = new Subject<string>();
  public brand: string;
  public currentBrand: BrandEnum | string = BrandEnum['Il Makiage'];
  public styling: LookCardStylingInterface = {
    simpleView: false,
    showReviewLabel: false,
    brandLogo: null,
    style: {}
  };
  private loadLooks$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private myLooksService: MyLooksService,
    private adminInfluencersService: AdminInfluencersService,
    private brandService: BrandService,
    private windowService: WindowService
  ) {
  }

  ngOnInit() {
    this.windowService.scrollTop();
    const userData: IUserAuthResolveInterface = this.route.snapshot.parent.data.userData;
    this.influencerId = userData.influencerId;
    this.isAdmin = userData.isAdmin;
    this.isContentEditor = (userData && userData.role === 'content_editor');

    this.subscribeOnLoadLooks();
    this.adminInfluencersService.getNewInfluencerCall()
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(data => {
        this.influencerId = data.influencerId;
        this.loadMyLooks();
      });

    this.loadMyLooks();
    this.subscribeOnBrandChanged();
  }

  private subscribeOnLoadLooks() {
    combineLatest([this.brand$, this.loadLooks$]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
      this.myLooksData$ = this
        .myLooksService
        .getLooksByInfluencer(this.influencerId)
        .pipe(
          map((entries: LookItemInterface[]) => {
              const stack = [];
              entries.forEach((entry: LookItemInterface) => {
                if (entry.brand === BrandModelKeys[value[0]]) {
                  stack.push(entry);
                }
              });
              return stack;
            }
          ));

      this.activeLooksCount$ = this.myLooksService.getLooksCountByInfluencer(this.influencerId, [1, 3], BrandTypeRelation[value[0]]);
      this.draftLooksCount$ = this.myLooksService.getLooksCountByInfluencer(this.influencerId, [0], BrandTypeRelation[value[0]]);
    });
  }

  private subscribeOnBrandChanged() {
    this.brandService
      .getCurrentBrand()
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        tap(data => {
          this.currentBrand = data === '' ? this.brandService.defaultBrand : data;
          this.brand = data;
          this.brand$.next(this.currentBrand);
        })
      )
      .subscribe(data => this.calculateStyling());
  }

  private calculateStyling() {
    this.styling.simpleView = this.currentBrand === BrandEnum['SpoiledChild'];
    this.styling.showReviewLabel = this.styling.simpleView;
    this.styling.brandLogo = BrandLogos[this.currentBrand];
    this.styling.style = BrandLogosStyle[this.currentBrand];
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public loadMyLooks() {
    this.loadLooks$.next(true);
  }
}
