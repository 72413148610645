import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject, Subscription} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {NzDropdownService} from 'ng-zorro-antd';
import {RegistrationService} from '../../../../services/registration.service';
import {AdminInfluencersService} from '../../../../services/admin-influencers.service';
import {CustomAdminUserService} from '../../../../services/custom-admin-user.service';
import {MyProfileService} from '../../../../services/my-profile.service';
import {InfluencerProfileInterface} from '../../../../interfaces/influencer-profile.interface';
import {IUserAuthResolveInterface} from '../../../../interfaces/user-auth-resolve.interface';
import {BrandService} from '../../../../services/brand.service';
import {BrandEnum, UserRoleEnum} from '@enums';
import {BrandRulesManager, RULE_ALLOW_EDIT_REVIEWS} from '../../../../helpers/BrandRulesManager';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../state/state';
import {setInfluencer} from '../../../../state/actions';
import {selectInfluencer} from '../../../../state/selectors';

@Component({
  selector: 'app-main-layout-header',
  templateUrl: './main-layout-header.component.html',
  styleUrls: ['./main-layout-header.component.less']
})
export class MainLayoutHeaderComponent implements OnInit, OnDestroy {

  public isMenuOpen = false;
  public isAdmin: boolean;
  public isContentEditor: boolean;
  public allInfluencersData$: Observable<InfluencerProfileInterface[]>;
  public selectedInfluencerId: string = this.customAdminUserService.customInfluencerId;
  public profile: InfluencerProfileInterface;
  public profile$ = new Subject<InfluencerProfileInterface>();
  public influencers: InfluencerProfileInterface[];
  userRoles: typeof UserRoleEnum = UserRoleEnum;
  role: string;
  public brand: string;
  public isDefaultBrand: boolean;
  public userBrandList = [];
  public subscription = new Subscription();

  constructor(
    private registrationService: RegistrationService,
    private adminInfluencersService: AdminInfluencersService,
    private nzDropdownService: NzDropdownService,
    private router: Router,
    private customAdminUserService: CustomAdminUserService,
    private route: ActivatedRoute,
    private myProfileService: MyProfileService,
    private brandService: BrandService,
    private store: Store<{ app: AppState }>
  ) {
  }

  ngOnInit() {
    const userData: IUserAuthResolveInterface = this.route.snapshot.data.userData;

    this.isContentEditor = (userData && userData.role === 'content_editor');
    this.role = userData && userData.role ? userData.role : UserRoleEnum.Default;
    this.isAdmin = userData && userData.isAdmin;

    this.subscribeOnProfileChange();

    if (this.isContentEditor || this.isAdmin) {
      this.allInfluencersData$ = this.adminInfluencersService.getAllInfluencers()
        .pipe(
          map((influencers: InfluencerProfileInterface[]) =>
            influencers.filter(influencer => influencer.isVisibleForAdminList !== false)
          ),
          tap((influencers: InfluencerProfileInterface[]) => {
            const selectedInfluencerId = influencers && influencers[0] && influencers[0]._id;
            this.influencers = influencers;
            if (!this.selectedInfluencerId && selectedInfluencerId) {
              this.selectedInfluencerId = selectedInfluencerId;
              this.setInfluencer(selectedInfluencerId);
            }
          })
        );
    }

    this.subscribeOnInfluencerSelection();

    this.brandService
      .getCurrentBrand()
      .subscribe(data => {
        this.brand = data === '' || data === null ? BrandEnum['Il Makiage'] : data;
        this.isDefaultBrand = this.brand === this.brandService.defaultBrand;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public subscribeOnProfileChange() {
    this.subscription.add(
      this
        .profile$
        .subscribe((data: InfluencerProfileInterface) => {
          this.profile = data;
          this.userBrandList = this.profile.brand;
          // @ts-ignore
          if (this.profile.brand.length === 0) {
            this.brandService.setCurrentBrand(this.brandService.defaultBrand);
          } else if (this.profile.brand.indexOf(this.brandService.getCurrentBrand().value) === -1) {
            this.brandService.setCurrentBrand(this.profile.brand[0] ? this.profile.brand[0] : this.brandService.defaultBrand);
          }
          if (!this.brandService.getCurrentBrand().value) {
            this.brandService.setCurrentBrand(this.profile.brand[0] ? this.profile.brand[0] : '');
          }
        })
    );
  }

  public subscribeOnInfluencerSelection() {
    this.subscription.add(
      this.store
        .pipe(
          select(selectInfluencer),
          filter(e => !!e)
        )
        .subscribe((data: InfluencerProfileInterface) => this.profile$.next(data))
    );
  }

  public signout(): void {
    this
      .registrationService
      .signoutUser()
      .then(() => {
        this.brandService.flushCurrentBrand();
        this.customAdminUserService.customInfluencerId = undefined;
        this.router.navigate(['/sign-in']);
      })
      .catch(err => console.error(err));
  }

  public setInfluencer(influencerId: string, forcedBrand: boolean = false) {
    this.customAdminUserService.customInfluencerId = influencerId;
    this.adminInfluencersService.sendNewInfluencerCall(influencerId);
    this
      .myProfileService
      .getInfluencerProfileData(influencerId)
      .subscribe(data => {
        this.store.dispatch(setInfluencer({influencer: data}));
      });
  }

  public toggleMenu(event: boolean) {
    setTimeout(() => {
      if (document.getElementsByClassName('mobile-view-menu') &&
        document.getElementsByClassName('mobile-view-menu')[0] &&
        !document.getElementsByClassName('mobile-view-menu')[0].parentElement.classList.contains('mobile-view-dropdown')) {

        // add 'mobile-view-dropdown' in case it doesn't exist
        document.getElementsByClassName('mobile-view-menu')[0].parentElement.classList.add('mobile-view-dropdown');
      }
    });
    this.isMenuOpen = event;
  }

  logoNavigation() {
    if (this.isContentEditor) {
      const path = BrandRulesManager.isActionAvailable(this.brand, RULE_ALLOW_EDIT_REVIEWS) ? '/my-reviews' : '/my-looks';
      this.router.navigate([path]);
      this.selectedInfluencerId = this.influencers[0]._id;
      this.setInfluencer(this.influencers[0]._id);
    } else if (this.isAdmin) {
      this.router.navigate(['/dashboard'])
        .catch(err => console.error(err));
    } else {
      const path = BrandRulesManager.isActionAvailable(this.brand, RULE_ALLOW_EDIT_REVIEWS) ? '/my-reviews' : '/my-looks';
      this.router.navigate([path]);
    }
  }
}
