import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LookItemInterface} from '../../interfaces/look-item.interface';
import {CarouselsEditingService} from '../../services/carousels-editing.service';

@Component({
  selector: 'app-carousels-editing',
  templateUrl: './carousels-editing.component.html',
  styleUrls: ['./carousels-editing.component.less']
})
export class CarouselsEditingComponent implements OnInit, OnDestroy {
  private reloadData$: Subject<void> = new Subject();
  private unsubscribe$: Subject<void> = new Subject();

  public favoritesLooks: { top, topEyes, topLips };
  public allLooks$: Observable<LookItemInterface[]>;
  public topLook;
  public topEyesLook;
  public topLipsLook;

  constructor(private carouselsEditingService: CarouselsEditingService) {
  }

  ngOnInit() {
    this.reloadData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.loadLooksData();

        setTimeout(() => {
          window.scrollTo(0, Number(window.localStorage.getItem('positionToScroll')));
          window.localStorage.removeItem('positionToScroll');
          }, 1000);
      });

    this.reloadData$.next();

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private loadLooksData() {
    window.scroll(0, 0);
    this.topLook = undefined;
    this.topEyesLook = undefined;
    this.topLipsLook = undefined;

    this.allLooks$ = this.carouselsEditingService.getAllLooks();

    this.carouselsEditingService.getAllFavoritesLooks()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => this.favoritesLooks = data);
  }

  public isNotSelected(id: string): boolean {
    return [...this.favoritesLooks.top, ...this.favoritesLooks.topEyes, ...this.favoritesLooks.topLips]
      .findIndex(look => look._id === id) === -1;
  }

  public verifyForm(data: LookItemInterface[], category: string): boolean {
    return data.every((look) => !!(look && look[category] > 0));
  }

  public submitFavorites(data: LookItemInterface[], category: string) {
    this.carouselsEditingService.updateFavoritesData(
      data.map(look => (({_id, top, topEyes, topLips}) => ({_id, top, topEyes, topLips}))(look)),
      category
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        window.localStorage.setItem('positionToScroll', window.pageYOffset.toString());
        this.reloadData$.next();
      });
  }

  public addLookToFavorites(id: string, category: string) {
    this.carouselsEditingService.addLookToFavorites(id, category, 0)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        window.localStorage.setItem('positionToScroll', window.pageYOffset.toString());
        this.reloadData$.next();
      });
  }

  public removeLookFromFavorites(id: string): void {
    this.carouselsEditingService.removeLookFromFavorites(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        window.localStorage.setItem('positionToScroll', window.pageYOffset.toString());
        this.reloadData$.next();
      });
  }

}
