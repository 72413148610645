import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap, takeUntil} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {NewLookService} from '../../services/new-look.service';
import {EditLookService} from '../../services/edit-look.service';
import {LookItemInterface} from '../../interfaces/look-item.interface';
import {IUserAuthResolveInterface} from '../../interfaces/user-auth-resolve.interface';
import {AdminInfluencersService} from '../../services/admin-influencers.service';
import {WindowService} from '../../services/window.service';
import {BrandService} from '../../services/brand.service';
import {BrandEnum, BrandLogosHeader, BrandLogoSizesClass} from '@enums';
import {BrandRulesManager, RULE_ALLOW_EDIT_REVIEWS, RULE_SHOW_LOOK_STEP, RULE_SHOW_REVIEW_STEP} from '../../helpers/BrandRulesManager';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/state';
import {setAddItemHidden, setAddItemShown, setPreloaderHidden} from '../../state/actions';

@Component({
  selector: 'app-edit-look-container',
  templateUrl: './edit-look-container.component.html',
  styleUrls: ['./edit-look-container.component.less']
})
export class EditLookContainerComponent implements OnInit, OnDestroy {

  public readonly brandLogos = BrandLogosHeader;
  public readonly brandLogoSizeClass = BrandLogoSizesClass;
  public readonly rules = {
    showLookStep: RULE_SHOW_LOOK_STEP,
    showReviewStep: RULE_SHOW_REVIEW_STEP
  };
  public lookData: LookItemInterface;
  public newLookPayload: any = {};
  public current = 0;
  public influencerId: string;
  public isAdmin: boolean;
  public isContentEditor: boolean;
  public submitLoading = false;
  public countryCode: string;
  public brand: string = BrandEnum['Il Makiage'];
  public styling: {
    isReview: boolean
  } = {
    isReview: false
  };
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private newLookService: NewLookService,
    private adminInfluencersService: AdminInfluencersService,
    private editLookService: EditLookService,
    private route: ActivatedRoute,
    private windowService: WindowService,
    private brandService: BrandService,
    private store: Store<{ app: AppState }>
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(setAddItemHidden());
    this.store.dispatch(setPreloaderHidden());
    this.windowService.scrollTop();
    const userData: IUserAuthResolveInterface = this.route.snapshot.parent.data.userData;
    this.influencerId = this.route.snapshot.params['influencerId'];
    this.isAdmin = userData.isAdmin;
    this.isContentEditor = (userData && userData.role === 'content_editor');

    this.lookData = this.route.snapshot.data.entry;
    this.newLookPayload._id = this.route.snapshot.params['entryId'];

    if (this.lookData && this.lookData.influencerRef) {
      this.countryCode = this.lookData.influencerRef.country;
    }
    this.subscribeToBrandChange();
  }

  ngOnDestroy() {
    this.store.dispatch(setAddItemShown());
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private subscribeToBrandChange() {
    this.brandService
      .getCurrentBrand()
      .pipe(
        takeUntil(this.destroy$),
        switchMap(brand => of((brand === '' ? this.brandService.defaultBrand : brand)))
      )
      .subscribe(brand => {
        this.brand = brand;
        this.calculateStyling(brand);
      });
  }

  private calculateStyling(brand: string) {
    this.styling.isReview = brand !== this.brandService.defaultBrand;
  }

  private static getLookStatusToSubmit(status: number, look: LookItemInterface, isReview: boolean): number {
    if (isReview) {
      return (look && look.video && look.afterShoot && look.applicationShoot && !!look.products) ? status : 0;
    }
    return (look && look.video && look.beforeImage && look.afterImage && !!look.products) ? status : 0;
  }

  public setStep(step: number): void {
    this.current = step;
    this.windowService.scrollIntoViewByObjectId('scroll-top', 'smooth');
  }

  public formSubmit(data: LookItemInterface): void {
    this.submitLoading = true;
    this.newLookPayload = {...this.newLookPayload, ...data};
    this.newLookPayload.status = EditLookContainerComponent
        .getLookStatusToSubmit(this.newLookPayload.status, this.lookData, this.styling.isReview);

    this.newLookService.upsertNewLook(this.newLookPayload)
      .subscribe((results: any) => {
        this.submitLoading = false;
        if (!this.newLookPayload._id && results._id) {
          this.newLookPayload._id = results._id;
        }

        this.lookData = results;

        if (data.step === 4) {
          const path = BrandRulesManager.isActionAvailable(this.brand, RULE_ALLOW_EDIT_REVIEWS) ? '/my-reviews' : '/my-looks';
          this.router.navigate([path])
            .catch((err) => console.log(err));
        } else {
          this.setStep(data.step);
        }
      });
  }
}
