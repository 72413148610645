import {Component, OnDestroy, OnInit} from '@angular/core';
import {BrandService} from '../../../services/brand.service';
import {distinctUntilChanged, takeUntil, tap} from 'rxjs/operators';
import {BrandEnum} from '@enums';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-add-new',
  templateUrl: './add-new.component.html',
  styleUrls: ['./add-new.component.less']
})
export class AddNewComponent implements OnInit, OnDestroy {

  public styling: { showReviewLabel: boolean, simpleView: boolean } = {
    simpleView: false,
    showReviewLabel: false
  };
  private destroy$ = new Subject();
  private currentBrand: BrandEnum | string = BrandEnum['Il Makiage'];

  constructor(
    private brandService: BrandService
  ) {
  }

  ngOnInit() {
    this.subscribeOnBrandChanged();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  private subscribeOnBrandChanged() {
    this.brandService
      .getCurrentBrand()
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        tap(data => {
          this.currentBrand = data === '' ? this.brandService.defaultBrand : data;
        })
      )
      .subscribe(data => this.calculateStyling());
  }

  private calculateStyling() {
    this.styling.simpleView = this.currentBrand === BrandEnum['SpoiledChild'];
    this.styling.showReviewLabel = this.styling.simpleView;
  }

}
