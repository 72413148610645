import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BrandEnum, BrandLogosHeader, BrandRouteRelation} from '@enums';
import {BrandService} from '../../services/brand.service';
import {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-brand-switcher',
  templateUrl: './brand-switcher.component.html',
  styleUrls: ['./brand-switcher.component.less']
})
export class BrandSwitcherComponent implements OnInit, OnChanges {

  @Input() brandsAllowed = [];
  public readonly brands: Array<{ name: string, src_part: string }> = [
    {
      name: BrandEnum['Il Makiage'],
      src_part: BrandLogosHeader[BrandEnum['Il Makiage']]
    },
    {
      name: BrandEnum['SpoiledChild'],
      src_part: BrandLogosHeader[BrandEnum['SpoiledChild']]
    }
  ];
  public brand: string = BrandEnum['Il Makiage'];
  public selectedBrand = 0;
  public isDropdownOpen = false;
  public allowBrandSwitching = false;
  private subscription = new Subscription();

  constructor(
    private brandService: BrandService,
    private router: Router
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.allowBrandSwitching = this.brandsAllowed.length > 1;
  }

  ngOnInit() {
    this.subscription.add(
      this.brandService
        .getCurrentBrand()
        .pipe(distinctUntilChanged())
        .subscribe(e => {
          this.brand = e === '' || e === null ? BrandEnum['Il Makiage'] : e;
          // tslint:disable-next-line:no-shadowed-variable
          this.selectedBrand = this.brands.findIndex((e) => e.name === this.brand);
        })
    );
  }

  public switchBrand(brand) {
    this.brandService.setCurrentBrand(brand.name);
    this.toggleDropdown();
    this.router.navigateByUrl(BrandRouteRelation[brand.name]);
  }

  public toggleDropdown() {
    if (!this.allowBrandSwitching) {
      return;
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
