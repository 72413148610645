import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-influencers-products-ordering-layout',
  templateUrl: './influencers-products-ordering-layout.component.html',
  styleUrls: ['./influencers-products-ordering-layout.component.less']
})
export class InfluencersProductsOrderingLayoutComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
