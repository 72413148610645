import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {AmplifyService} from 'aws-amplify-angular';

@Injectable({
  providedIn: 'root'
})
export class OnlyAdminsGuard implements CanActivate {

  constructor(private router: Router, private amplifyService: AmplifyService) {
  }

  canActivate(): UrlTree | boolean {
    return this.amplifyService.auth().currentUserInfo()
      .then(user => {
        if (!user) {
          this.router.navigateByUrl('/')
            .catch(err => console.error(err));
          return false;
        }

        const isAdmin = !!(user.attributes && user.attributes['custom:role'] && user.attributes['custom:role'] === 'admin');
        if (isAdmin) {
          return true;
        } else {
          this.router.navigateByUrl('/')
            .catch(err => console.error(err));
          return false;
        }
      });

  }
}
