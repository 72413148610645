import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {IUserAuthResolveInterface} from '../../interfaces/user-auth-resolve.interface';
import {AdminInfluencersService} from '../../services/admin-influencers.service';
import {InfluencerProfileInterface} from '../../interfaces/influencer-profile.interface';
import {MyProfileService} from '../../services/my-profile.service';
import {BrandService} from '../../services/brand.service';
import {takeUntil} from 'rxjs/operators';
import {WindowService} from '../../services/window.service';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.less']
})
export class BrandsComponent implements OnInit, OnDestroy {
  public profile: any;
  public isAdmin: boolean;
  public isContentEditor: boolean;
  public currentBrand: string;
  public loadingData = true;
  private influencerId: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private myProfileService: MyProfileService,
    private adminInfluencersService: AdminInfluencersService,
    private brandService: BrandService,
    private windowService: WindowService
  ) {
  }

  ngOnInit() {
    this.windowService.scrollTop();
    const userData: IUserAuthResolveInterface = this.route.snapshot.parent.data.userData;
    this.isAdmin = userData.isAdmin;
    this.isContentEditor = (userData && userData.role === 'content_editor');
    this.influencerId = userData.influencerId;

    this
      .adminInfluencersService
      .getNewInfluencerCall()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.influencerId = data.influencerId;
        this.getInfluencerProfileData();
      });

    this.getInfluencerProfileData();
    this
      .brandService
      .getCurrentBrand()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(data => this.currentBrand = data);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  chooseBrand(item: string) {
    this.currentBrand = item;
  }

  public confirm() {
    this.brandService.setCurrentBrand(this.currentBrand);
  }

  itemClass(item: string): string {
    return item.replace(/\s/g, '');
  }

  private getInfluencerProfileData() {
    this
      .myProfileService
      .getInfluencerProfileData(this.influencerId)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((data: InfluencerProfileInterface) => {
        this.profile = data;
        this.loadingData = false;
      });
  }
}
