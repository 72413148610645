import {LOOK_ITEM, REVIEW_ITEM} from '../services/edit-look.service';

export enum BrandEnum {
  'Il Makiage' = 'Il Makiage',
  'SpoiledChild' = 'SpoiledChild'
}

export enum BrandLogos {
}

BrandLogos[BrandEnum['Il Makiage']] = 'ilmakiage';
BrandLogos[BrandEnum['SpoiledChild']] = 'spoiledchild';

export enum BrandLogosStyle {
}

BrandLogosStyle[BrandEnum['Il Makiage']] = {
  height: '31px',
  width: '86px',
  'margin-left': '0px',
  'margin-bottom': '10px'
};
BrandLogosStyle[BrandEnum['SpoiledChild']] = {
  height: '21px',
  width: '136px'
};

export enum BrandModelKeys {
}

BrandModelKeys[BrandEnum['Il Makiage']] = 'il-makiage';
BrandModelKeys[BrandEnum['SpoiledChild']] = 'spoiled-child';

export enum BrandTagsLimits {
}

BrandTagsLimits[BrandEnum['Il Makiage']] = 5;
BrandTagsLimits[BrandEnum['SpoiledChild']] = 6;

export enum BrandLogosHeader {
}

BrandLogosHeader[BrandEnum['Il Makiage']] = 'logo-header.svg';
BrandLogosHeader[BrandEnum['SpoiledChild']] = 'brand-spoiledchild.svg';

export enum BrandLogoSizesClass {}

BrandLogoSizesClass[BrandEnum['Il Makiage']] = 'img__large';
BrandLogoSizesClass[BrandEnum['SpoiledChild']] = 'img__medium';

export enum BrandShootExamples {}

BrandLogoSizesClass[BrandEnum['Il Makiage']] = 'ilm';
BrandLogoSizesClass[BrandEnum['SpoiledChild']] = 'sc';

export enum BrandTypeRelation {}

BrandTypeRelation[BrandEnum['Il Makiage']] = LOOK_ITEM;
BrandTypeRelation[BrandEnum['SpoiledChild']] = REVIEW_ITEM;

export enum BrandRouteRelation {}

BrandRouteRelation[BrandEnum['Il Makiage']] = '/my-looks';
BrandRouteRelation[BrandEnum['SpoiledChild']] = '/my-reviews';

export enum BrandProductRelation {}

BrandProductRelation[BrandEnum['Il Makiage']] = 'InfluencerLookCreation';
BrandProductRelation[BrandEnum['SpoiledChild']] = 'InfluencerReviewCreation';
