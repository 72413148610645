import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-modal-custom-look-component',
  templateUrl: './dashboard-modal-custom-look.component.html',
  styleUrls: ['./dashboard-modal-custom-look.component.less']
})
export class DashboardModalCustomLookComponent implements OnInit {

  @Input() item: DashboardMyLooksInterface;

  constructor() {
  }

  ngOnInit() {
  }

}
