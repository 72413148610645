import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-question-tooltip',
  templateUrl: './question-tooltip.component.html',
  styleUrls: ['./question-tooltip.component.less']
})
export class QuestionTooltipComponent implements OnInit {

  @Input() public tooltipText;

  constructor() {
  }

  ngOnInit() {
  }

}
