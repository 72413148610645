import {Component, ComponentRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IVideoTimestampItemInterface} from '../../../../../interfaces/video-timestamp-item.interface';

@Component({
  selector: 'app-dynamic-video-timestamp-selector',
  templateUrl: './dynamic-video-timestamp-selector.component.html',
  styleUrls: ['./dynamic-video-timestamp-selector.component.less']
})
export class DynamicVideoTimestampSelectorComponent implements OnInit, OnChanges {
  @Output() public selectedTimestamp = new EventEmitter<{ index: number, data?: IVideoTimestampItemInterface }>();
  @Input() public isFirst: boolean;
  @Input() public index: number;
  @Input() public videoTimestamp: IVideoTimestampItemInterface;

  public _ref: ComponentRef<DynamicVideoTimestampSelectorComponent>;
  public defaultVideoPointersValue = new Date(0, 0);
  public nzFormat = 'HH:mm';
  public sectionsForm: FormGroup;
  public popupClassName = 'timepicker-popup';

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.initForm(this.videoTimestamp);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.videoTimestamp && changes.videoTimestamp.currentValue && this.videoTimestamp) {
      this.initForm(this.videoTimestamp);
      if (!this.sectionsForm.controls['category'].value && !this.sectionsForm.controls['timestamp'].value) {
        this.sectionsForm.patchValue({
          category: this.videoTimestamp && this.videoTimestamp.category,
          timestamp: this.setTimestamp()
        });
      }
    }
  }

  private initForm(data?: IVideoTimestampItemInterface) {
    let timestampValue = null;

    if (data && data.timestamp) {
      timestampValue = this.setTimestamp();
    }

    if (!this.sectionsForm) {
      this.sectionsForm = this.fb.group({
        category: [(data && data.category) || null, [Validators.required]],
        timestamp: [timestampValue, [Validators.required]]
      });
      this.sectionsForm.valueChanges
        .subscribe((timeStampData: IVideoTimestampItemInterface) => {
          if (this.sectionsForm.valid) {
            this.selectedTimestamp.emit({
              index: this.index,
              data: {category: timeStampData.category, timestamp: timeStampData.timestamp}
            });
          }
        });
    }
  }

  private setTimestamp(): Date {
    const [hours, minutes] = this.videoTimestamp.timestamp.split(':');
    return new Date(0, 0, 0, parseInt(hours, 10), parseInt(minutes, 10), 0);
  }

  public onOpenChange(isOpened: boolean) {
    if (isOpened) {
      setTimeout(() => {
        const element = document.querySelector('.' + this.popupClassName + ' input');
        if (element !== null) {
          element.setAttribute('readonly', 'readonly');
        }
      }, 0);
    }
  }

  public removeObject(): void {
    this.sectionsForm.reset();
    this._ref.destroy();
    this.selectedTimestamp.emit({index: this.index});
  }

}
