import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {AmplifyService} from 'aws-amplify-angular';
import {BrandService} from '../services/brand.service';

@Injectable({
  providedIn: 'root'
})
export class NotContentEditorGuard implements CanActivate {

  constructor(
    private router: Router,
    private amplifyService: AmplifyService,
    private brandService: BrandService
  ) {
  }

  canActivate(): UrlTree | boolean {
    return this.amplifyService.auth().currentUserInfo()
      .then(user => {
        if (!user) {
          this.router.navigateByUrl('/sign-in')
            .catch(err => console.error(err));
          return false;
        }

        if (user.attributes && user.attributes['custom:role'] === 'admin') {
          return true;
        } else {
          this.router.navigateByUrl('/my-looks')
            .catch(err => console.error(err));
          return false;
        }
      });
  }
}
